<template>
  <v-runtime-template :template="render" v-if="render"></v-runtime-template>
</template>

<script>
import VRuntimeTemplate from 'vue3-runtime-template'
import btn from '@/components/btn/btn'
import docs from '@/components/docs/docs'
import step from '@/components/step/step'
import accordion from '@/components/accordion/accordion'
import newsCard from '@/components/newsCard/newsCard'
import handBookCard from '@/components/handBookCard/handBookCard.vue'

export default {
  name: 'renderContent',
  components: {
    VRuntimeTemplate,
    /* eslint-disable */
    btn,
    docs,
    step,
    accordion,
    newsCard,
    handBookCard
  },
  props: ['render']
}
</script>
