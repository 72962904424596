<template>
  <div class="search">
    <input class="search__input" :value="value" type="text" @input="$emit('customInput', $event.target.value)" :placeholder="placeholder">
    <headerBtnIconSearch class="search__icon" />
  </div>
</template>

<script>
import headerBtnIconSearch from '@/components/headerApp/headerBtnIcon/headerBtnIcons/headerBtnIconSearch'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'search',
  props: ['placeholder', 'value'],
  components: {
    headerBtnIconSearch
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.search
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  position: relative
  width: 100%
.search__input
  @include resetBtn
  @include borderBox
  width: 100%
  font-family: 'Raleway', sans-serif
  font-weight: 700
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  color: $black
  border-bottom: 1px solid $black
  padding: 3px 0 8px 36px
  &::placeholder
    font-weight: 400
    color: $darkGray
  &:focus + .search__icon
    path
      fill: $red

.search__icon
  position: absolute
  top: 50%
  left: 0
  transform: translateY(calc(-50% - 4px))
  path
    @include transition
</style>
