<template>
  <div class="header__menu" :class="headerMenuBtn ? 'active' : ''">
    <div class="header__menu-wrapper">
      <search :value="search" @customInput="getSearchMethod" class="header__menu-search" placeholder="поиск по сайту" />
      <router-link class="header__menu-icon-link" :class="getLk ? 'active' : ''" to="/favorites">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1685 14.8557L9.50133 14.4335L8.83345 14.8545L4.51583 17.576L5.67976 12.3502L5.83779 11.6406L5.29926 11.1524L1.35507 7.57639L6.49091 7.12023L7.25139 7.05268L7.53854 6.34526L9.49985 1.51332L11.4611 6.35597L11.7479 7.06424L12.5091 7.13184L17.6449 7.58801L13.7007 11.164L13.1622 11.6523L13.3202 12.3618L14.484 17.5868L10.1685 14.8557ZM17.7756 7.4695L17.7752 7.46989L17.7756 7.4695Z" fill="white" stroke="#2C2A29" stroke-width="2.5"/>
        </svg>
        <span>Избранное</span>
      </router-link>
      <a class="header__menu-icon-link" target="_blank" href="http://xn--80aer5aza.xn--90aefhe5axg6g1a.xn--p1ai/">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7778 3.77778L1.91667 13.6667C1.69444 13.8889 1.43037 14 1.12444 14C0.819259 14 0.555555 13.8889 0.333333 13.6667C0.111111 13.4444 0 13.1804 0 12.8744C0 12.5693 0.111111 12.3056 0.333333 12.0833L10.2222 2.22222H1.77778C1.46296 2.22222 1.19889 2.11593 0.985556 1.90333C0.772963 1.69 0.666667 1.42593 0.666667 1.11111C0.666667 0.796296 0.772963 0.532222 0.985556 0.318889C1.19889 0.106296 1.46296 0 1.77778 0H12.8889C13.2037 0 13.4674 0.106296 13.68 0.318889C13.8933 0.532222 14 0.796296 14 1.11111V12.2222C14 12.537 13.8933 12.8007 13.68 13.0133C13.4674 13.2267 13.2037 13.3333 12.8889 13.3333C12.5741 13.3333 12.3104 13.2267 12.0978 13.0133C11.8844 12.8007 11.7778 12.537 11.7778 12.2222V3.77778Z" fill="#2C2A29"/>
        </svg>
        <span>Старая версия сайта</span>
      </a>
      <div class="header__menu-search-result" v-if="getSearch.items">
        <searchCard :url="item.url" :title="item.title" :breadcrumbs="item.breadCrumbs" :date="item.date" v-for="(item, key) in getSearch.items.slice(0,4)" :key="key" />
        <router-link class="header__menu-search-result-more" :to="'/search?search=' + search">Найдено результатов: {{getSearch.pageNav.pageRecordCount}}</router-link>
      </div>
      <div class="header__menu-inner" v-if="!getSearch.items">
        <headerMenuList :title="item.text" :items="item.item" v-for="(item, key) in items" :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
import search from '@/components/search/search'
import headerMenuList from '@/components/headerApp/headerMenu/headerMenuList/headerMenuList'
import searchCard from '@/components/searchCard/searchCard'
let timerId = null
export default {
  name: 'headerMenu',
  props: ['items'],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    headerMenuBtn () {
      if (!this.$store.state.header.headerMenuBtn) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.search = ''
        this.$store.commit('stateHeaderSearch', '')
      }
      return this.$store.state.header.headerMenuBtn
    },
    getSearch () {
      return this.$store.state.search.searchHeader
    },
    getLk () {
      return this.$store.state.header.lk
    }
  },
  methods: {
    getSearchMethod (e) {
      const $this = this
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(function () {
        if (e === '') {
          $this.search = ''
          $this.$store.commit('stateHeaderSearch', '')
          $this.$store.commit('stateSearch', '')
        } else {
          $this.search = e
          $this.$store.dispatch('getHeaderSearch', e)
        }
      }, 1500)
    }
  },
  components: {
    search,
    headerMenuList,
    searchCard
  }
}
</script>

<style lang="sass">
@import "../../../mixin"
@import "../../../variables"
.header__menu
  @include transition
  position: fixed
  top: 100px
  left: 0
  width: 100%
  height: calc(100% - 100px)
  background: rgba(0, 0, 0, 0.2)
  pointer-events: none
  opacity: 0
  overflow-y: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  &.active
    pointer-events: auto
    opacity: 1
  &::-webkit-scrollbar
    display: none
.header__menu-search-result
  @include borderBox
  @include flexColumn
  max-width: 1214px
  width: 100%
.header__menu-wrapper
  @include borderBox
  @include flexColumn
  @include flexAlignItemsCenter
  width: 100%
  height: auto
  padding: 60px 40px
  background: $white
.header__menu-search
  max-width: 1214px
  margin: 0 0 40px 0
.header__menu-inner
  @include flexAlignItemsStart
  @include flexRowWrap
  width: 100%
.header__menu-search-result-more
  @include transition
  width: max-content
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 170%
  letter-spacing: 0.03em
  text-decoration: none
  color: $black
  margin: 20px 0 0 0
  border-bottom: 2px solid $black20
  &:hover
    background: $lightGray
    border-bottom: 2px solid $lightGray
.header__menu-icon-link
  @include resetBtn
  display: none
  align-items: center
  margin: 0 auto 20px 0
  text-transform: none
  svg
    flex: 0 0 auto
  &.active
    path
      fill: $red
  &:hover
    text-decoration: underline #2C2A29
  span
    font-family: 'Jost', sans-serif
    font-style: normal
    font-weight: 400
    font-size: 25px
    line-height: 36px
    letter-spacing: 0.03em
    color: #2C2A29
    margin: 0 0 0 20px
@media (max-width: 1364px)
  .header__menu
    top: 60px
    height: 100%
@media (max-width: 767px)
  .header__menu-icon-link
    display: flex
</style>
