import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/keep-up/news',
    component: () => import('@/views/NewsPage.vue')
  },
  {
    path: '/keep-up/news/:id',
    component: () => import('@/views/NewsDetailPage.vue')
  },
  {
    path: '/keep-up/events',
    component: () => import('@/views/EventPage.vue')
  },
  {
    path: '/keep-up/events/:id',
    component: () => import('@/views/EventDetailPage.vue')
  },
  {
    path: '/keep-up/events-archive',
    component: () => import('@/views/EventArchivePage.vue')
  },
  {
    path: '/keep-up/events-archive/:id',
    component: () => import('@/views/EventArchiveDetailPage.vue')
  },
  {
    path: '/keep-up/success-histories',
    component: () => import('@/views/SuccessHistoriesPage.vue')
  },
  {
    path: '/keep-up/success-histories/:id',
    component: () => import('@/views/SuccessHistoriesDetailPage.vue')
  },
  {
    path: '/keep-up/online',
    component: () => import('@/views/OnlineShowcasePage.vue')
  },
  {
    path: '/support',
    component: () => import('@/views/Support.vue')
  },
  {
    path: '/support/:id',
    component: () => import('@/views/SupportDetail.vue')
  },
  {
    path: '/handbook',
    component: () => import('@/views/HandBookPage.vue')
  },
  {
    path: '/handbook/:id',
    component: () => import('@/views/HandBookPage.vue')
  },
  {
    path: '/fund/documents',
    component: () => import('@/views/DocumentsPage.vue')
  },
  {
    path: '/fund/contacts',
    component: () => import('@/views/ContactsPage.vue')
  },
  {
    path: '/support-centers/ciks',
    component: () => import('@/views/Ciks.vue')
  },
  {
    path: '/support-centers/citkp',
    component: () => import('@/views/Сitkp.vue')
  },
  {
    path: '/support-centers/cpe',
    component: () => import('@/views/Cpe.vue')
  },
  {
    path: '/support-centers/cfp',
    component: () => import('@/views/Cfp.vue')
  },
  {
    path: '/support-centers/cop',
    component: () => import('@/views/Cop.vue')
  },
  {
    path: '/support-centers/ciss',
    component: () => import('@/views/Ciss.vue')
  },
  {
    path: '/search',
    component: () => import('@/views/SearchPage.vue')
  },
  {
    path: '/favorites',
    component: () => import('@/views/FavoritesPage.vue')
  },
  {
    path: '/knowledge',
    component: () => import('@/views/KnowledgePage.vue')
  },
  {
    path: '/knowledge/:id',
    component: () => import('@/views/KnowledgeDetailPage.vue')
  },
  {
    path: '/projects',
    component: () => import('@/views/ProjectPage.vue')
  },
  {
    path: '/projects/:id',
    component: () => import('@/views/ProjectDetail.vue')
  },
  {
    path: '/excursions',
    component: () => import('@/views/ExcursionsPage.vue')
  },
  {
    path: '/assistant',
    component: () => import('@/views/AssistantPage.vue')
  },
  {
    path: '/special-page',
    component: () => import('@/views/SpecialPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/StaticPage.vue')
  },
  {
    path: '/trainers/:id',
    component: () => import('@/views/TrainersPage.vue')
  },
  {
    path: '/generator',
    component: () => import('@/views/GeneratorPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.afterEach((to, from) => {
//   window.scrollTop(0)
// })

export default router
