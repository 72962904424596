import { axiosGet } from '@/service/axios'

export const homePage = {
  state () {
    return {
      banner: '',
      minNews: '',
      tags: '',
      events: '',
      online: '',
      successHistories: '',
      news: ''
    }
  },
  mutations: {
    stateBanner (state, data) {
      state.banner = data
    },
    stateMinNews (state, data) {
      state.minNews = data
    },
    stateTags (state, data) {
      state.tags = data
    },
    stateEvents (state, data) {
      state.events = data
    },
    stateOnline (state, data) {
      state.online = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    stateNews (state, data) {
      state.news = data
    },
    stateNewsMore (state, data) {
      state.news = state.news.concat(data)
    }
  },
  actions: {
    getHomeBanner ({ dispatch, commit }) {
      axiosGet('api/main/banner', {}, (data) => {
        if (data.length !== 0) {
          commit('stateBanner', data)
        } else {
          commit('stateBanner', '')
        }
      })
    },
    getHomeMinNews ({ dispatch, commit }) {
      axiosGet('api/main/minNews', {}, (data) => {
        if (data.length !== 0) {
          commit('stateMinNews', data)
        } else {
          commit('stateMinNews', '')
        }
      })
    },
    getHomeTags ({ dispatch, commit }) {
      axiosGet('api/main/tags', {}, (data) => {
        if (data.length !== 0) {
          commit('stateTags', data)
        } else {
          commit('stateTags', '')
        }
      })
    },
    getHomeEvents ({ dispatch, commit }, data) {
      const act = data ? '?date=' + data : ''
      axiosGet('api/main/events' + act, {}, (data) => {
        if (data.length !== 0) {
          commit('stateEvents', data)
        } else {
          commit('stateEvents', '')
        }
      })
    },
    getHomeOnline ({ dispatch, commit }) {
      axiosGet('api/main/online', {}, (data) => {
        if (data.length !== 0) {
          commit('stateOnline', data)
        } else {
          commit('stateOnline', '')
        }
      })
    },
    getHomeSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/main/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data.items)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getHomeNews ({ dispatch, commit }) {
      axiosGet('api/main/news', {}, (data) => {
        if (data.length !== 0) {
          commit('stateNews', data)
        } else {
          commit('stateNews', '')
        }
      })
    },
    getHomeNewsMore ({ dispatch, commit }) {
      axiosGet('api/main/news?more=true', {}, (data) => {
        if (data.length !== 0) {
          commit('stateNewsMore', data)
        } else {
          commit('stateNewsMore', '')
        }
      })
    }
  }
}
