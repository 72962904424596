<template>
  <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1263_58212)">
      <path d="M31.8741 0L30.2038 1.67528L28 3.88573L25.7962 1.67528L24.1259 0H0V16.9157C0 19.4984 0.324772 21.9881 0.997515 24.3847C1.67026 26.7813 2.59818 29.015 3.82767 31.1091C5.05717 33.2032 6.51864 35.0879 8.2121 36.7865C9.90555 38.485 11.8078 39.9509 13.8724 41.1841C15.937 42.4173 18.164 43.348 20.5534 44.0228C22.9428 44.6975 25.425 45.0233 28 45.0233C30.575 45.0233 33.0572 44.6975 35.4466 44.0228C37.836 43.348 40.063 42.4173 42.1276 41.1841C44.1922 39.9509 46.0944 38.485 47.7879 36.7865C49.4814 35.0879 50.966 33.2032 52.1723 31.1091C53.4018 29.015 54.3297 26.7813 55.0025 24.3847C55.6752 21.9881 56 19.4984 56 16.9157V0H31.8741ZM44.7954 16.9157C44.7954 19.2425 44.3546 21.4297 43.4731 23.4773C42.5915 25.5248 41.3853 27.3164 39.8542 28.8521C38.3231 30.3878 36.5601 31.5745 34.5186 32.4586C32.4772 33.3428 30.3198 33.7616 28 33.7616C25.6802 33.7616 23.4996 33.3195 21.4582 32.4586C19.4167 31.5745 17.6305 30.3878 16.0994 28.8521C14.5684 27.3164 13.3853 25.5248 12.5037 23.4773C11.6222 21.4297 11.2046 19.2425 11.2046 16.9157V11.3314H44.7954V16.9157Z" fill="#FF5069"/>
    </g>
    <defs>
      <clipPath id="clip0_1263_58212">
        <rect width="56" height="45" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'footerWebsiteDevelopmentDD'
}
</script>
