import { axiosGet } from '@/service/axios'
import router from '@/router'

export const breadCrumbs = {
  state () {
    return {
      items: ''
    }
  },
  mutations: {
    stateBreadCrumbs (state, data) {
      state.items = data
    }
  },
  actions: {
    getBreadCrumbs ({ dispatch, commit }) {
      const url = router.currentRoute.value.path
      axiosGet(`api/bread-crumbs${url}`, {}, (data) => {
        if (data.length !== 0) {
          commit('stateBreadCrumbs', data)
        } else {
          commit('stateBreadCrumbs', '')
        }
      })
    }
  }
}
