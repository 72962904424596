import { axiosGet } from '@/service/axios'
import router from '@/router'

export const news = {
  state () {
    return {
      items: ''
    }
  },
  mutations: {
    stateItems (state, data) {
      state.items = data
    }
  },
  actions: {
    getItemsAndPageNav ({ dispatch, commit }) {
      axiosGet(`api${router.currentRoute.value.fullPath}`, {}, (data) => {
        if (data.length !== 0) {
          commit('stateItems', data)
        } else {
          commit('stateItems', '')
        }
      })
    }
  }
}
