<template>
  <button class="btnGetForm" :class="classCustom" @click="click(idForm, idItem)" v-if="idForm">
    <span>{{text}}<component v-bind:is="iconName"></component></span>
    <span>{{text}}<component v-bind:is="iconName"></component></span>
  </button>
</template>

<script>

import Calendar from '@/components/btn/btnIcon/btnIconCalendar'
import Plus from '@/components/btn/btnIcon/btnIconPlus'

export default {
  name: 'btnGetForm',
  props: ['idForm', 'iconName', 'text', 'classCustom', 'idItem'],
  components: {
    components: {
      Calendar,
      Plus
    }
  },
  methods: {
    click (id, idItem) {
      if (String(id).indexOf('http') === -1) {
        this.$store.dispatch('getFormsContent', id)
        this.$store.commit('stateFormIdItem', idItem)
        this.$store.commit('stateOpenForm', true)
      } else {
        window.open(id, '_blank')
      }
    }
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.btnGetForm
  @include flexRowWrap
  @include flexAlignItemsCenter
  @include flexJustifyContentCenter
  @include resetBtn
  @include transition
  @include borderBox
  position: relative
  height: fit-content
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 14px
  text-align: center
  letter-spacing: 0.1em
  text-transform: uppercase
  color: $black
  cursor: pointer
  border: 1px solid $black
  border-radius: 60px
  padding: 19px 25px
  margin: auto 40px auto auto
  overflow: hidden
  span:nth-child(1)
    @include transition
    @include flexRowWrap
    @include flexAlignItemsCenter
    position: relative
  span:nth-child(2)
    @include transition
    @include flexRowWrap
    @include flexAlignItemsCenter
    position: absolute
    top: 100%
    color: $white
    & path
      @include transition
      fill: $white
    & line
      @include transition
      stroke: $white
  &:before
    @include transition
    position: absolute
    top: 100%
    width: 100%
    height: 100%
    left: 0
    background: $black
    content: ''
  svg
    @include transition
    margin: 0 0 0 14px
  & path
    @include transition
    fill: $black
  & line
    @include transition
    stroke: $black
  &:hover
    &:before
      top: 0
    span:nth-child(2)
      top: 50%
      transform: translateY(-50%)
    span:nth-child(1)
      transform: translateY(-30px)
      opacity: 0
  &.white
    border: 1px solid $white
    color: $white
    & path
      fill: $white
    & line
      stroke: $white
    &:before
      background: $white
    span:nth-child(2)
      color: $black
      & path
        fill: $black
      & line
        stroke: $black
  &.disabled
    pointer-events: none
    opacity: 0.2
@media (max-width: 767px)
  .btnGetForm
    span
      font-size: 14px
    svg
      margin: 0 0 0 5px
    &:before
      display: none
    span:nth-child(2)
      display: none
    &:hover
      span:nth-child(1)
        transform: none
        opacity: 1
</style>
