<template>
  <label class="checkbox">
    <input type="checkbox" :name="name" @click="$emit('checked', $event.target.checked)" hidden>
    <i>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3.17172C9 3.3064 8.95183 3.42088 8.85548 3.51515L5.11871 7.17172L4.41677 7.85859C4.32043 7.95286 4.20344 8 4.06581 8C3.92817 8 3.81118 7.95286 3.71484 7.85859L3.0129 7.17172L1.14452 5.34343C1.04817 5.24916 1 5.13468 1 5C1 4.86532 1.04817 4.75084 1.14452 4.65657L1.84645 3.9697C1.9428 3.87542 2.05979 3.82828 2.19742 3.82828C2.33505 3.82828 2.45204 3.87542 2.54839 3.9697L4.06581 5.4596L7.45161 2.14141C7.54796 2.04714 7.66495 2 7.80258 2C7.94022 2 8.0572 2.04714 8.15355 2.14141L8.85548 2.82828C8.95183 2.92256 9 3.03704 9 3.17172Z" fill="#2C2A29"/>
      </svg>
    </i>
    <span>Я согласен на обработку персональных данных</span>
  </label>
</template>

<script>
export default {
  name: 'checkBox',
  props: ['name']
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.checkbox
  display: flex
  align-items: center
  flex-wrap: nowrap
  cursor: pointer
  i
    @include resetBtn
    display: inline-flex
    align-items: center
    justify-content: center
    flex: 0 0 auto
    width: 20px
    height: 20px
    border: 1px solid $darkGray
    margin: 0 10px 0 0
    svg
      display: none
  span
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 160%
    letter-spacing: 0.03em
    color: $black
  input:checked ~ i
    border: 2px solid $black
    margin: 0 8px 0 0
    svg
      display: block
</style>
