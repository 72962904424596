<template>
  <div class="header__menu-list">
    <h3 class="header__menu-list-title" v-if="title != 'Меры поддержки'">{{title}}</h3>
    <router-link class="header__menu-list-title clickable" v-else :to="'/support'">{{title}}</router-link>
    <ul class="header__menu-list-ul" v-for="(item, key) in items" :key="key">
      <li v-if="!item.item">
        <router-link v-if="item.url.indexOf('http') === -1" :to="item.url">{{item.text}}</router-link>
        <a v-if="item.url.indexOf('http') !== -1" class="" :href="item.url" target="_blank">{{item.text}}</a>
      </li>
      <li class="list" v-if="item.item">
        <button @click="click($event)" class="header__menu-list-btn"></button>
        <router-link v-if="item.url.indexOf('http') === -1" :to="item.url">{{item.text}}</router-link>
        <a v-if="item.url.indexOf('http') !== -1" class="" :href="item.url" target="_blank">{{item.text}}</a>
        <ul>
          <li v-for="(items1, key) in item.item" :key="key">
            <router-link v-if="item.url.indexOf('http') === -1" :to="items1.url">{{items1.text}}</router-link>
            <a v-if="items1.url.indexOf('http') !== -1" class="" :href="items1.url" target="_blank">{{items1.text}}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'headerMenuList',
  props: ['title', 'items'],
  methods: {
    click (e) {
      e.currentTarget.closest('.list').classList.toggle('active')
    }
  }
}
</script>

<style lang="sass">
@import "../../../../mixin"
@import "../../../../variables"
.header__menu-list
  @include flexColumn
  max-width: 280px
  font-family: 'Jost', sans-serif
  font-weight: 700
  font-size: 20px
  line-height: 160%
  letter-spacing: 0.1em
  color: $black
  margin: 0 100px 20px 0
  &:nth-child(5)
    margin: 0
.header__menu-list-title
  font-family: 'Jost', sans-serif
  font-weight: 400
  font-size: 25px
  line-height: 160%
  letter-spacing: 0.1em
  color: $black
  margin: 0 0 20px 0
.header__menu-list-title.clickable
  text-transform: none
  text-decoration: none
  &:hover
    text-decoration: underline
.header__menu-list-ul
  list-style: none
  margin: 0
  li
    margin: 0
  li:last-child
    margin: 0 0 10px 0
  li:before
    display: none
  a
    @include resetBtn
    font-family: 'Raleway', sans-serif
    font-size: 18px
    line-height: 160%
    color: $black
    margin: 0 0 20px 0
    text-transform: none
    &:hover
      text-decoration: underline
  ul
    @include transition
    height: 0
    overflow: hidden
    padding: 0 0 0 20px
    margin: 0!important
    border-left: 1px solid $black20
  .list
    position: relative
    @include flexColumn
    margin: 0 0 10px 40px !important
    p
      cursor: pointer
      font-family: 'Raleway', sans-serif
      font-size: 18px
      line-height: 160%
      color: $black
      margin: 0 0 20px 0
      text-transform: none
    .header__menu-list-btn
      @include transition
      @include resetBtn
      display: block
      position: absolute
      top: 0
      left: -40px
      content: ''
      width: 30px
      height: 30px
      border: none
      margin: 0
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUiIGN5PSIxNSIgcj0iMTQuNSIgc3Ryb2tlPSIjRTc0NzJEIi8+CjxsaW5lIHgxPSIxNS41IiB5MT0iOCIgeDI9IjE1LjUiIHkyPSIyMiIgc3Ryb2tlPSIjRTc0NzJEIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+CjxsaW5lIHgxPSIyMiIgeTE9IjE1LjUiIHgyPSI4IiB5Mj0iMTUuNSIgc3Ryb2tlPSIjRTc0NzJEIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=)
    &:hover
      .header__menu-list-btn
        transform: rotate(45deg)
    &.active
      &:hover
        .header__menu-list-btn
          transform: rotate(0deg)
      .header__menu-list-btn
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUiIGN5PSIxNSIgcj0iMTQuNSIgc3Ryb2tlPSIjRTc0NzJEIi8+CjxsaW5lIHgxPSIyMiIgeTE9IjE1LjUiIHgyPSI4IiB5Mj0iMTUuNSIgc3Ryb2tlPSIjRTc0NzJEIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=)
      ul
        height: auto
@media (max-width: 1364px)
  .header__menu-list
    width: 432px
    margin: 0 30px 20px 0
</style>
