<template>
  <router-link class="footer__link" v-if="type === 'router'" :to="url">{{text}}</router-link>
  <a class="footer__link" v-if="type === 'link'" :href="url">{{text}}</a>
</template>

<script>
export default {
  name: 'footerLink',
  props: ['type', 'url', 'text']
}
</script>

<style lang="sass">
@import "../../../variables"
@import "../../../mixin"
.footer__link
  @include resetBtn
  @include transition
  font-family: 'Jost', sans-serif
  font-weight: 600
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.1em
  text-transform: uppercase
  color: $black
  &:hover
    color: $red
</style>
