<template>
  <div class="footer__copyright">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'footerCopyright',
  props: ['text']
}
</script>

<style lang="sass">
@import "../../../variables"
.footer__copyright
  max-width: 461px
  font-family: 'Raleway',sans-serif
  font-weight: 400
  font-size: 14px
  line-height: 160%
  color: $black
  p
    margin: 0
  p:not(:last-child)
    margin: 0 0 14px 0
@media (max-width: 1364px)
  .footer__copyright
    order: 1
    margin: 0 0 40px 0
</style>
