<template>
  <div class="footer">
    <footerCopyright v-if="getCopyright" v-html="getCopyright" />
    <div class="footer__wrapper">
      <div class="footer__inner">
        <footerLink type="router" v-if="getLink.url" :url="getLink.url" :text="getLink.text" />
        <footerLink type="link" url="mailto: info@mb-ugra.ru" text="info@mb-ugra.ru" />
        <div class="footer__soc" v-if="getSoc">
          <footerBtnIcon :url="item.url" v-html="item.icon" v-for="(item, key) in getSoc" :key="key" />
        </div>
      </div>
      <webpImg class="footer__img" :srcWebp="require('@/assets/img/webp/Biznes_bez_kovid_16.webp')" :src="require('@/assets/img/jpgPng/Biznes_bez_kovid_16.png')" alt="" />
    </div>
    <footerWebsiteDevelopment />
    <button @click="clickTopScrollPage" class="footer__website-development-btn-top">
      <svg width="28" height="56" viewBox="0 0 28 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6368 42.3292L17.3399 46.0322L13.6369 53.7521L9.93384 46.0322L13.6368 42.3292Z" stroke="#E7472D" stroke-width="1.5"/>
        <path d="M15.9731 18.0337C15.9731 19.3884 14.8749 20.4866 13.5203 20.4866C12.1656 20.4866 11.0674 19.3884 11.0674 18.0337C11.0674 16.679 12.1656 15.5808 13.5203 15.5808C14.8749 15.5808 15.9731 16.679 15.9731 18.0337Z" stroke="#E7472D" stroke-width="1.5"/>
        <path d="M20.4951 38.4364C20.225 38.9948 19.9899 39.4667 19.8035 39.8349L14.2793 34.3108L13.7489 33.7804L13.2186 34.3108L7.69286 39.8365C7.50658 39.4704 7.27189 39.0014 7.00228 38.4462C6.30458 37.0097 5.37447 34.9992 4.44485 32.7042C2.5738 28.085 0.75 22.4204 0.75 17.9674C0.75 12.9243 3.77324 8.73519 7.03439 5.69465C9.84425 3.07486 12.744 1.39038 13.7559 0.843174C14.7666 1.37638 17.6765 3.06116 20.492 5.6939C23.7423 8.7333 26.7479 12.9227 26.7479 17.9674C26.7479 22.381 24.9243 28.0454 23.0528 32.6754C22.1231 34.9755 21.1929 36.9935 20.4951 38.4364ZM17.3589 31.2311L18.2602 32.1324L18.6104 30.9068L18.6106 30.906C18.6108 30.9054 18.611 30.9046 18.6114 30.9035L18.6142 30.8943L18.6243 30.863C18.6329 30.8368 18.6441 30.8033 18.6583 30.7617C18.6833 30.6882 18.7153 30.5957 18.7541 30.4835L18.7702 30.4368C18.862 30.1713 18.9851 29.8142 19.1281 29.3853C19.4139 28.5279 19.7793 27.3823 20.1354 26.0966C20.8404 23.5506 21.531 20.3782 21.4515 17.7992C21.4476 14.259 19.6749 11.5281 17.9026 9.68453C16.1305 7.84111 14.2971 6.81782 14.0062 6.67237L13.6708 6.50466L13.3354 6.67237C13.0445 6.81782 11.2111 7.84111 9.43902 9.68453C7.6648 11.5301 5.89012 14.2651 5.89012 17.8111C5.89012 20.3412 6.59838 23.5098 7.31421 26.0692C8.03217 28.6361 8.78452 30.6818 8.91928 30.9962L9.35566 32.0144L10.139 31.2311L13.7489 27.6211L17.3589 31.2311Z" stroke="#E7472D" stroke-width="1.5"/>
      </svg>
      <span>вверх</span>
    </button>
  </div>
</template>

<script>
import footerCopyright from '@/components/footerApp/footerCopyright/footerCopyright'
import footerLink from '@/components/footerApp/footerLink/footerLink'
import footerBtnIcon from '@/components/footerApp/footerBtnIcon/footerBtnIcon'
import webpImg from '@/components/webpImg/webpImg'
import footerWebsiteDevelopment from '@/components/footerApp/footerWebsiteDevelopment/footerWebsiteDevelopment'

export default {
  name: 'footerApp',
  components: {
    footerCopyright,
    footerLink,
    footerBtnIcon,
    webpImg,
    footerWebsiteDevelopment
  },
  methods: {
    clickTopScrollPage () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    getCopyright () {
      return this.$store.state.footer.copyright
    },
    getLink () {
      return this.$store.state.footer.link
    },
    getSoc () {
      return this.$store.state.footer.soc
    }
  },
  mounted () {
    this.$store.dispatch('getFooterCopyright')
    this.$store.dispatch('getFooterLink')
    this.$store.dispatch('getFooterSoc')
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.footer
  @include flexRowWrap
  @include flexAlignItemsCenter
  @include borderBox
  position: relative
  width: 100%
  flex: 0 0 auto
  border-top: 1px solid $black
  margin: 60px 0 0 0
  padding: 66px 40px 59px
.footer__wrapper
  @include flexRowWrap
  @include flexAlignItemsCenter
  margin: 0 auto 0 158px
.footer__inner
  @include flexRowWrap
  @include flexJustifyContentSpace
  width: 654px
.footer__soc
  @include flexRowWrap
  @include flexJustifyContentSpace
  @include flexAlignItemsCenter
  width: 150px
.footer__img
  margin: 0 0 0 131px
  img
    width: 100px
    height: 100px
.footer__website-development-btn-top
  @include resetBtn
  @include flexColumn
  @include flexAlignItemsCenter
  @include flexJustifyContentCenter
  @include transition
  cursor: pointer
  position: absolute
  top: -60px
  left: auto
  right: 40px
  width: 100px
  height: 100px
  border: 2px solid $red
  border-radius: 100%
  background: $white
  path, span
    @include transition
  &:hover
    background: $red
    path, span
      color: $white
      stroke: $white
  span
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 160%
    color: $black
@media (max-width: 1919px)
  .footer
    margin: 40px 0 0 0
  .footer__website-development-btn-top
    top: -40px
    width: 80px
    height: 80px
    span
      display: none
  .footer__wrapper
    margin: 0 auto 0 111px
  .footer__inner
    @include flexColumn
    width: auto
    height: 130px
  .footer__img
    margin: 0 0 0 111px
@media (max-width: 1364px)
  .footer
    @include flexColumn
    @include flexAlignItemsStart
    padding: 40px 19px
    margin: 0
  .footer__wrapper
    width: 100%
    order: 0
    margin: 0 0 40px 0
  .footer__inner
    height: 170px
  .footer__img
    margin: 0 0 0 auto
  .footer__website-development-btn-top
    top: auto
    right: 31px
    bottom: 70px
    width: 60px
    height: 60px
    svg
      height: 50px
  .footer__margin
    padding: 40px 19px 100px 19px
@media (max-width: 767px)
  .footer
    padding: 40px 15px
  .footer__img
    margin: auto 0 0 auto
    img
      width: 70px
      height: 70px
  .footer__website-development-btn-top
    width: 40px
    height: 40px
    top: 41px
    right: 15px
    bottom: auto
    border: 1px solid $red
    svg
      height: 32px
  .footer__margin
    padding: 40px 15px 100px 15px
</style>
