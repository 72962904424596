<template>
  <label class="input-custom"
         :class="[required ? 'required' : '', type === 'checkbox' ? 'checkbox' : '', type === 'radio' ? 'radio' : '', classCustom, errorMsg ? 'error' : '', name === 'dop_city' ? 'dop-city hide' : '']"
         v-if="type !== 'select' && type !== 'toggle' && (typeInput ? typeInput.includes(filterInput) : true)">
    <input
            v-if="type !== 'file'"
           :ref="dynamicRef"
           class="input-custom__input"
           :type="type === 'number' ? 'string' : type === 'date' ? 'string' :  type"
           :required="required"
           :name="name"
           :value="value"
           :hidden="type === 'file'"
           :placeholder="placeholder">
    <input
      v-if="type === 'file'"
      :ref="dynamicRef"
      class="input-custom__input"
      type="file"
      :required="required"
      :name="name"
      :value="value"
      hidden
      @change="previewFiles"
      :placeholder="placeholder">
    <span class="input-custom__input-file" :class="fileName ? 'selected' : ''" v-if="type === 'file'">{{fileName ? fileName : placeholder}}</span>
    <i>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3.17172C9 3.3064 8.95183 3.42088 8.85548 3.51515L5.11871 7.17172L4.41677 7.85859C4.32043 7.95286 4.20344 8 4.06581 8C3.92817 8 3.81118 7.95286 3.71484 7.85859L3.0129 7.17172L1.14452 5.34343C1.04817 5.24916 1 5.13468 1 5C1 4.86532 1.04817 4.75084 1.14452 4.65657L1.84645 3.9697C1.9428 3.87542 2.05979 3.82828 2.19742 3.82828C2.33505 3.82828 2.45204 3.87542 2.54839 3.9697L4.06581 5.4596L7.45161 2.14141C7.54796 2.04714 7.66495 2 7.80258 2C7.94022 2 8.0572 2.04714 8.15355 2.14141L8.85548 2.82828C8.95183 2.92256 9 3.03704 9 3.17172Z" fill="#2C2A29"/>
      </svg>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="3" fill="#D5D4D4" stroke="#2C2A29" stroke-width="2"/>
      </svg>
    </i>
    <span class="input-custom__placeholder">{{placeholder}}</span>
    <span class="input-custom__error">! {{errorMsg}}</span>
  </label>
  <selectForm :errorMsg="errorMsg" :items="items" :name="name" :placeholder="placeholder" :required="required" :multiple="multiple" :class="[classCustom, required ? 'required' : '']" v-if="type === 'select' && (typeInput ? typeInput.includes(filterInput) : true)" />
</template>

<script>
import Inputmask from 'inputmask'
import selectForm from '@/components/selectForm/selectForm'
export default {
  name: 'inputCustom',
  props: ['type', 'placeholder', 'required', 'name', 'value', 'dynamicRef', 'classCustom', 'items', 'multiple', 'errorMsg', 'typeInput', 'filterInput'],
  data () {
    return {
      fileName: '',
      mask: {
        phone: {
          mask: '+7 (#99)-999-99-99',
          definitions: {
            '#': {
              validator: '[12345679]'
            }
          }
        },
        string: '',
        email: 'email',
        number: { regex: '[0-9]*' },
        date: '99.99.9999'
      }
    }
  },
  components: {
    selectForm
  },
  mounted () {
    if (this.$props.type !== 'select' && this.$props.type !== 'toggle' && this.$props.type !== 'file' && this.$props.name !== 'captcha_word' && (this.$props.typeInput ? this.$props.typeInput.includes(this.$props.filterInput) : true)) {
      new Inputmask(this.mask[this.$props.type]).mask(this.$refs[this.$props.dynamicRef])
    }
  },
  methods: {
    previewFiles (e) {
      this.fileName = e.target.files[0].name
    }
  }
}
</script>

<style lang="sass">
@import "@/mixin.sass"
@import "@/variables.sass"
.input-custom, .input-custom__input-file
  display: block
  position: relative
  width: 100%
  padding: 3px 0 8px 0
  margin: 0 0 48px 0
  &.error
    .input-custom__error
      display: block
    .input-custom__input
      border-bottom: 1px solid $red
  i
    display: none
    svg
      display: none
  &.required
    &:after
      position: absolute
      top: 50%
      left: -10px
      transform: translateY(-50%)
      font-family: 'Raleway', sans-serif
      font-style: normal
      font-weight: 400
      font-size: 25px
      line-height: 160%
      letter-spacing: 0.03em
      color: $red
      content: '*'
  &.checkbox, &.radio
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: center
    cursor: pointer
    i
      @include resetBtn
      display: inline-flex
      align-items: center
      justify-content: center
      flex: 0 0 auto
      width: 20px
      height: 20px
      border: 1px solid $darkGray
      margin: 0 10px 0 0
    .input-custom__placeholder
      display: block !important
      position: relative
      top: auto
      left: auto
      font-family: 'Raleway', sans-serif
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 160%
      letter-spacing: 0.03em
      color: $black
    input:checked ~ i
      border: 2px solid $black
      margin: 0 8px 0 0
      svg:first-child
        display: block
      svg:last-child
        display: none
    input
      display: none
  &.radio
    i
      border-radius: 100%
    input:checked ~ i
      svg:first-child
        display: none
      svg:last-child
        display: block
.dop-city
  display: block
  &.hide
    display: none
.input-custom__error
  display: none
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 130%
  color: $red
.constructor-form__input
  &.required
    &:after
      position: absolute
      top: 50%
      left: -10px
      transform: translateY(-50%)
      font-family: 'Raleway', sans-serif
      font-style: normal
      font-weight: 400
      font-size: 25px
      line-height: 160%
      letter-spacing: 0.03em
      color: $red
      content: '*'
.input-custom__input
  @include resetBtn
  position: relative
  width: 100%
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  color: $black
  border-bottom: 1px solid $darkGray
  z-index: 1
  &::placeholder
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 160%
    letter-spacing: 0.03em
    color: $darkGray
.input-custom__placeholder
  display: none
  position: absolute
  top: 3px
  left: 0
  width: 100%
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  color: $darkGray
.input-custom__input-file
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  color: $darkGray
  margin: 0
  border-bottom: 1px solid $darkGray
  padding: 0 15px 5px 0
  box-sizing: border-box
  cursor: pointer
  &.selected
    color: $black
    border-bottom: 1px solid $black
</style>
