<template>
  <router-link class="news-card" :to="url" v-if="url && url.indexOf('http') === -1">
    <img loading="lazy" class="news-card__img" :src="host + img" v-if="img">
    <div class="news-card__info">
      <div class="news-card__info-source">
        <webpImg class="news-card__info-img" :src="host + icon" alt="" v-if="icon" />
        <p class="news-card__info-source-name" v-if="source">{{source}}</p>
      </div>
      <span class="news-card__info-publication-date" v-if="date">{{date}}</span>
    </div>
    <p class="news-card__text" v-if="title">
      {{ title }}
    </p>
  </router-link>
  <a class="news-card" :href="url" target="_blank" v-if="url && url.indexOf('http') !== -1">
    <img loading="lazy" class="news-card__img" :src="host + img" v-if="img">
    <div class="news-card__info">
      <div class="news-card__info-source">
        <webpImg class="news-card__info-img" :src="host + icon" alt="" v-if="icon" />
        <p class="news-card__info-source-name" v-if="source">{{source}}</p>
      </div>
      <span class="news-card__info-publication-date" v-if="date">{{date}}</span>
    </div>
    <p class="news-card__text" v-if="title">
      {{ title }}
    </p>
  </a>
</template>

<script>
import webpImg from '@/components/webpImg/webpImg'
import { host } from '@/const/host'

export default {
  name: 'newsCard',
  props: ['url', 'icon', 'title', 'date', 'img', 'source'],
  components: {
    webpImg
  },
  data () {
    return {
      host
    }
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.news-card
  @include flexColumn
  @include resetBtn
  @include borderBox
  width: 368px
  height: auto
  padding: 40px 40px 20px 40px
  border-right: 1px solid $black
  border-bottom: 1px solid $black
  &:hover
    .news-card__text
      text-decoration: underline
  &.news-page
    height: auto
.news-card__img
  width: 100%
  height: 185px
  object-fit: cover
  margin: 0 0 20px 0
.news-card__info
  min-height: 34px
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  margin: 0 0 40px 0
.news-card__info-img
  img
    width: 30px
    height: 30px
    object-fit: contain
    margin: 0 20px 0 0
.news-card__info-publication-date
  font-family: 'Jost', sans-serif
  font-weight: 500
  font-size: 12px
  line-height: 130%
  letter-spacing: 0.03em
  text-transform: uppercase
  color: $black
.news-card__text
  @include transition
  height: 147px
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 22px
  line-height: 160%
  color: $black
  overflow: hidden
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  display: -webkit-box
  margin: 0
.news-card__info-source
  position: relative
  display: flex
  &:hover
    .news-card__info-source-name
      display: block
.news-card__info-source-name
  position: absolute
  display: none
  top: -5px
  left: 40px
  margin: 0
  background: #F2F4FA
  border: 1px solid #2C2A29
  border-radius: 10px
  padding: 10px 20px
  font-size: 16px
@media (min-width: 1920px)
  .news-card
    &:nth-child(-n+4)
      border-right: 1px solid $black !important
      border-top: 0
    &:nth-child(5)
      border-top: 0
    &:nth-child(5n+5)
      border-right: 0
@media (max-width: 1919px) and (min-width: 1364px)
  .news-card
    width: 433px
    height: auto
    &:nth-child(-n+2)
      border-right: 1px solid $black !important
      border-top: 0
    &:nth-child(3)
      border-top: 0
    &:nth-child(3n+3)
      border-right: 0
  .news-card__text
    height: 78px
@media (max-width: 1364px) and (min-width: 768px)
  .news-card
    width: 365px
    &:nth-child(-n+1)
      border-right: 1px solid $black !important
      border-top: 0
    &:nth-child(2)
      border-top: 0
    &:nth-child(2n+2)
      border-right: 0
@media (max-width: 767px)
  .news-card
    width: 100%
    height: auto
    border-top: 1px solid $black
    border-right: 0
    padding: 20px
    &:first-child
      border-top: 0
  .news-card__text
    font-size: 18px
    height: auto
</style>
