import router from '@/router'
export const canonicalLinks = () => {
  const routeId = router.currentRoute.value.params.id // это определитель деталок
  const path = router.currentRoute.value.path
  const host = window.location.protocol + '//' + window.location.host
  const canonicalElement = document.querySelector('link[rel=canonical]')
  const addCanonicalLink = (url) => {
    const link = document.createElement('link')
    link.rel = 'canonical'
    link.href = url
    document.head.appendChild(link)
  }
  if (canonicalElement) {
    canonicalElement.remove()
  }
  if (routeId) {
    const routeIdSplit = routeId.split('-')[0]
    if (/^\d+$/.test(routeIdSplit)) { // если число
      addCanonicalLink(host + path.replace(routeId, routeIdSplit))
    } else {
      addCanonicalLink(host + path)
    }
  } else {
    addCanonicalLink(host + path)
  }
}
