import { axiosGet } from '@/service/axios'
import router from '@/router'

export const handbook = {
  state () {
    return {
      info: ''
    }
  },
  mutations: {
    stateInfo (state, data) {
      state.info = data
    }
  },
  actions: {
    getHandbookTags ({ dispatch, commit }) {
      const query = router.currentRoute.value.params.id
      axiosGet(query ? 'api/handbook/' + query : 'api/handbook', {}, (data) => {
        if (data.length !== 0) {
          commit('stateInfo', data)
        } else {
          commit('stateInfo', '')
        }
      })
    }
  }
}
