<template>
  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.44681 7.875L11 1.575L9.3617 -8.18426e-07L7.86805e-07 9L9.3617 18L11 16.425L4.44681 10.125L3.2766 9L4.44681 7.875Z" fill="#2C2A29"/>
  </svg>
</template>

<script>
export default {
  name: 'btnIconArrowBack'
}
</script>
