import { host } from '@/const/host'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
export const axiosGet = (url, data, callback) => {
  if (!url.includes('undefined')) {
    if (url !== 'api/favorites') {
      axios.get(host + url, data)
        .then(function (response) {
          document.querySelector('.loader').classList.add('disable')
          callback(response.data)
          if (('api' + router.currentRoute.value.fullPath === url || 'api' + router.currentRoute.value.fullPath === url + '/') && router.currentRoute.value.fullPath !== '/') {
            if (Object.keys(response.data).length === 0 && response.data.length === 0) {
              store.commit('mutationNotFound', true)
              window.YandexRotorSettings.IsError = true
            } else {
              setTimeout(() => {
                window.YandexRotorSettings.IsLoaded = true
                store.commit('mutationNotFound', false)
              }, 2000)
            }
          }
          if (router.currentRoute.value.fullPath === '/') {
            setTimeout(() => {
              window.YandexRotorSettings.IsLoaded = true
              store.commit('mutationNotFound', false)
            }, 2000)
          }
        })
        .catch((error) => {
          document.querySelector('.loader').classList.add('disable')
          if (error.response.status === 404) {
            window.YandexRotorSettings.IsError = true
            store.commit('mutationNotFound', true)
          }
        })
    }
  }
}
export const axiosPost = (url, data, callback) => {
  axios.post(host + url, data)
    .then(function (response) {
      document.querySelector('.loader').classList.add('disable')
      callback(response.data)
    })
    .catch((error) => {
      document.querySelector('.loader').classList.add('disable')
      if (error.response.status === 404) {
        store.commit('mutationNotFound', true)
      }
    })
}
