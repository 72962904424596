<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="11.5" y1="3" x2="11.5" y2="21" stroke="#2C2A29" stroke-linecap="round"/>
    <line x1="20.5" y1="12" x2="2.5" y2="12" stroke="#2C2A29" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'btnIconPlus'
}
</script>
