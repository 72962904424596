export const supportClickCard = {
  state () {
    return {
      url: ''
    }
  },
  mutations: {
    stateSupportClickCard (state, data) {
      state.url = data
    }
  }
}
