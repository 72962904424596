import { axiosGet } from '@/service/axios'

export const footer = {
  state () {
    return {
      copyright: '',
      link: '',
      soc: ''
    }
  },
  mutations: {
    stateCopyright (state, data) {
      state.copyright = data
    },
    stateFooterLink (state, data) {
      state.link = data
    },
    stateSoc (state, data) {
      state.soc = data
    }
  },
  actions: {
    getFooterCopyright ({ dispatch, commit }) {
      axiosGet('api/footer/copyright', {}, (data) => {
        if (data.length !== 0) {
          commit('stateCopyright', data.html)
        } else {
          commit('stateCopyright', '')
        }
      })
    },
    getFooterLink ({ dispatch, commit }) {
      axiosGet('api/footer/link', {}, (data) => {
        if (data.length !== 0) {
          commit('stateFooterLink', data)
        } else {
          commit('stateFooterLink', '')
        }
      })
    },
    getFooterSoc ({ dispatch, commit }) {
      axiosGet('api/footer/soc', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSoc', data)
        } else {
          commit('stateSoc', '')
        }
      })
    }
  }
}
