export const notFound = {
  state () {
    return {
      check: false
    }
  },
  mutations: {
    mutationNotFound (state, data) {
      state.check = data
    }
  }
}
