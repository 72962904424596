export const filterDate = {
  state () {
    return {
      date: ''
    }
  },
  mutations: {
    stateDate (state, data) {
      state.date = data
    }
  }
}
