import { axiosGet } from '@/service/axios'

export const CallBackForm = {
  state () {
    return {
      formId: ''
    }
  },
  mutations: {
    stateCallBackFormId (state, data) {
      state.formId = data
    }
  },
  actions: {
    getCallBackForm ({
      dispatch,
      commit
    }) {
      axiosGet('api/forms/callbackform', {}, (data) => {
        commit('stateCallBackFormId', data.formId)
      })
    }
  }
}
