import { axiosGet } from '@/service/axios'

export const ciss = {
  state () {
    return {
      header: '',
      content: '',
      offer: '',
      tags: '',
      centerProjects: '',
      online: '',
      events: '',
      excursion: '',
      successHistories: '',
      partners: '',
      question: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    offer (state, data) {
      state.offer = data
    },
    tags (state, data) {
      state.tags = data
    },
    centerProjects (state, data) {
      state.centerProjects = data
    },
    online (state, data) {
      state.online = data
    },
    events (state, data) {
      state.events = data
    },
    excursion (state, data) {
      state.excursion = data
    },
    successHistories (state, data) {
      state.successHistories = data
    },
    partners (state, data) {
      state.partners = data
    },
    question (state, data) {
      state.question = data
    }
  },
  actions: {
    getCissHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCissContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCissOffer ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/offer', {}, (data) => {
        if (data.length !== 0) {
          commit('offer', data)
        } else {
          commit('offer', '')
        }
      })
    },
    getCissEvents ({ dispatch, commit }, data) {
      const act = data ? '?date=' + data : ''
      axiosGet('api/support-centers/ciss/events' + act, {}, (data) => {
        if (data.length !== 0) {
          commit('events', data)
        } else {
          commit('events', '')
        }
      })
    },
    getCissTags ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/tags', {}, (data) => {
        if (data.length !== 0) {
          commit('tags', data)
        } else {
          commit('tags', '')
        }
      })
    },
    getCissCenterProjects ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/center-projects', {}, (data) => {
        if (data.length !== 0) {
          commit('centerProjects', data)
        } else {
          commit('centerProjects', '')
        }
      })
    },
    getCissOnline ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/online', {}, (data) => {
        if (data.length !== 0) {
          commit('online', data)
        } else {
          commit('online', '')
        }
      })
    },
    getCissExcursion ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/excursion', {}, (data) => {
        if (data.length !== 0) {
          commit('excursion', data)
        } else {
          commit('excursion', '')
        }
      })
    },
    getCissSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('successHistories', data)
        } else {
          commit('successHistories', '')
        }
      })
    },
    getCissPartners ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/partners', {}, (data) => {
        if (data.length !== 0) {
          commit('partners', data)
        } else {
          commit('partners', '')
        }
      })
    },
    getCissQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciss/question', {}, (data) => {
        if (data.length !== 0) {
          commit('question', data)
        } else {
          commit('question', '')
        }
      })
    }
  }
}
