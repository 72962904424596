<template>
  <div class="header" :class="headerStateOfScroll ? 'min' : ''">
    <headerMenuBtn/>
    <headerMenu :items="getMenu" />
    <div class="header__logo-wrapper">
      <headerLogo :url="item.url" :class="key === 1 ? 'bu' : key === 2 ? 'na' : key === 3 ? 'ge' : ''" v-for="(item, key) in getLogo" :key="key" />
    </div>
    <headerTel v-if="getTel" :url="'tel:' + getTel" :tel="getTel" />
    <headerLink v-if="getLink.url" :url="getLink.url" :text="getLink.text" />
    <headerBtnIcon @click="clickSearch" :class="getHeaderMenuBtn ? 'disable' : ''" icon-name="search" :text="'поиск'" />
    <a class="link-old-site" href="http://xn--80aer5aza.xn--90aefhe5axg6g1a.xn--p1ai/" target="_blank">
      <p>старая версия сайта</p>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7778 3.77778L1.91667 13.6667C1.69444 13.8889 1.43037 14 1.12444 14C0.819259 14 0.555555 13.8889 0.333333 13.6667C0.111111 13.4444 0 13.1804 0 12.8744C0 12.5693 0.111111 12.3056 0.333333 12.0833L10.2222 2.22222H1.77778C1.46296 2.22222 1.19889 2.11593 0.985556 1.90333C0.772963 1.69 0.666667 1.42593 0.666667 1.11111C0.666667 0.796296 0.772963 0.532222 0.985556 0.318889C1.19889 0.106296 1.46296 0 1.77778 0H12.8889C13.2037 0 13.4674 0.106296 13.68 0.318889C13.8933 0.532222 14 0.796296 14 1.11111V12.2222C14 12.537 13.8933 12.8007 13.68 13.0133C13.4674 13.2267 13.2037 13.3333 12.8889 13.3333C12.5741 13.3333 12.3104 13.2267 12.0978 13.0133C11.8844 12.8007 11.7778 12.537 11.7778 12.2222V3.77778Z" fill="#2C2A29"/>
      </svg>
    </a>
  </div>
</template>

<script>
import headerMenuBtn from '@/components/headerApp/headerMenuBtn/headerMenuBtn'
import headerLogo from '@/components/headerApp/headerLogo/headerLogo'
import headerTel from '@/components/headerApp/headerTel/headerTel'
import headerLink from '@/components/headerApp/headerLink/headerLink'
import headerBtnIcon from '@/components/headerApp/headerBtnIcon/headerBtnIcon'
import headerMenu from '@/components/headerApp/headerMenu/headerMenu'

export default {
  name: 'headerApp',
  components: {
    headerMenuBtn,
    headerLogo,
    headerTel,
    headerLink,
    headerBtnIcon,
    headerMenu
  },
  data () {
    return {
      headerStateOfScroll: false
    }
  },
  methods: {
    clickSearch () {
      this.$store.commit('stateActiveHeaderMenuBtn')
      document.querySelector('.header__menu-search input').focus()
    }
  },
  computed: {
    getHeaderMenuBtn () {
      return this.$store.state.header.headerMenuBtn
    },
    getLogo () {
      return this.$store.state.header.logo
    },
    getTel () {
      return this.$store.state.header.tel
    },
    getLink () {
      return this.$store.state.header.link
    },
    getMenu () {
      return this.$store.state.header.menu
    }
  },
  mounted () {
    this.$store.commit('stateLk')
    this.$store.dispatch('getHeaderLogo')
    this.$store.dispatch('getHeaderTel')
    this.$store.dispatch('getHeaderLink')
    this.$store.dispatch('getHeaderMenu')
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.header
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  @include transition
  @include borderBox
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: auto
  background: $white
  padding: 30px 40px 30px 40px
  border-bottom: 1px solid $black20
  z-index: 999
.header__logo-wrapper
  @include flexRowNoWrap
  @include flexJustifyContentSpace
  width: 333px
  margin: 0 217px 0 0
.lk
  margin: 0 60px 0 40px !important
.link-old-site
  position: absolute
  width: 16px
  height: 16px
  top: 52%
  transform: translateY(-50%)
  right: 40px
  flex: 0 0 auto
  background: transparent
  overflow: hidden
  border: 1px solid transparent
  cursor: pointer
  text-decoration: none
  &:hover
    width: auto
    height: auto
    background: $lightGray
    border: 1px solid $black
    padding: 5px 10px
    border-radius: 10px
    p
      display: block
    svg
      right: 10px
  svg
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: auto
    right: 0
  p
    position: relative
    top: 50%
    left: auto
    right: 0
    font-family: 'Jost', sans-serif
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 160%
    letter-spacing: 0.03em
    color: $black
    margin: 0 30px 0 0
    display: none
@media (max-width: 1919px)
  .header
    height: 100px
    padding: 25px 26px 15px 32px
    &.min
      padding: 25px 26px 15px 32px
      .header__logo-wrapper
        width: 342px
        margin: 0 60px 0 0
  .link-old-site
    top: 58%
    right: 26px
  .header__logo-wrapper
    width: 342px
    margin: 0 60px 0 0
@media (max-width: 1364px)
  .header
    height: 60px
    padding: 10px 20px
    &.min
      height: 60px
      padding: 10px 20px
      .header__logo-wrapper
        order: 0
        width: 222px
        margin: 0
  .header__logo-wrapper
    order: 0
    width: 222px
    margin: 0
  .link-old-site
    top: 55%
    right: 95px
  .lk
    position: absolute
    display: flex
    top: 50%
    right: 160px
    transform: translateY(-50%)
    margin: 0 !important
@media (max-width: 767px)
  .header
    height: 70px
    padding: 15px
    &.min
      height: 70px
      padding: 15px
  .link-old-site, .lk
    display: none
</style>
