import { axiosGet } from '@/service/axios'
import router from '@/router'

export const staticPage = {
  state () {
    return {
      content: '',
      header: ''
    }
  },
  mutations: {
    stateContent (state, data) {
      state.content = data
    },
    stateCentresHeader (state, data) {
      state.header = data
    }
  },
  actions: {
    getStaticContent ({ dispatch, commit }) {
      axiosGet(`api${router.currentRoute.value.fullPath}`, {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getStaticCentresHeader ({ dispatch, commit }) {
      if (router.currentRoute.value.params.pathMatch) {
        const pathHeader = router.currentRoute.value.params.pathMatch[0] + '/' + router.currentRoute.value.params.pathMatch[1] + '/'
        axiosGet(`api/${pathHeader}header`, {}, (data) => {
          if (data.length !== 0) {
            commit('stateCentresHeader', data)
          } else {
            commit('stateCentresHeader', '')
          }
        })
      }
    }
  }
}
