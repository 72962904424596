<template>
  <div class="breadcrumbs">
      <router-link class="breadcrumbs__link" v-for="(item, key) in getItems" :key="key" :to="item.url ? item.url : '/'">{{ item.text }} <span class="breadcrumbs__separator">/</span></router-link>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'breadcrumbs',
  computed: {
    getItems () {
      return this.$store.state.breadCrumbs.items
    }
  },
  mounted () {
    this.$store.dispatch('getBreadCrumbs')
  },
  watch: {
    '$route' (to, from) {
      this.$store.dispatch('getBreadCrumbs')
    }
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.breadcrumbs
  @include flexRowWrap
  margin: 140px 0 25px 0
  &.support-centers
    margin: 200px 0 25px 0
.breadcrumbs__link
  @include resetBtn
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 160%
  color: $black
  margin: 0 0 15px 0
  &:last-child
    color: $black20
    pointer-events: none
    cursor: default
    .breadcrumbs__separator
      display: none
.breadcrumbs__separator
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 160%
  color: $black
  margin: 0 15px
@media (max-width: 1919px)
  .breadcrumbs
    margin: 160px 0 25px 0
@media (max-width: 1364px)
  .breadcrumbs
    display: none
</style>
