<template>
  <div class="not-found">
    <vue-headful
      title="БизнесЮгры.рф – портал малого и среднего предпринимательства Югры"
      description="Финансовая, образовательная и информационная помощь предпринимателям Югры"/>
    <div class="not-found__wrapper">
      <h1>Ошибка 404</h1>
      <p>Такой страницы нет или она была удалена,
        но есть много полезного на других страницах. Начните с главной.</p>
      <btn url="/" text="перейти на главную страницу" />
    </div>
    <img loading="lazy" class="not-found__img" :src="require('@/assets/img/svg/Group.svg')">
  </div>
</template>

<script>
import btn from '@/components/btn/btn'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: '404',
  components: {
    btn
  },
  mounted () {
    document.querySelector('.loader').classList.add('disable')
  },
  watch: {
    '$route' (to, from) {
      this.$store.commit('mutationNotFound', false)
    }
  }
}
</script>

<style lang="sass">
@import "@/mixin.sass"
@import "@/variables.sass"
.not-found
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  position: relative
  width: 100%
  height: 100%
  overflow: hidden
.not-found__wrapper
  width: 100%
  max-width: 587px
  margin: 0 0 0 353px
  h1
    margin: 0 0 40px 0
  p
    margin: 0 0 40px 0
  a
    width: fit-content
    margin: 0 auto 0 0
.not-found__img
  margin: 0 -312px 0 auto
@media (max-width: 1919px)
  .not-found__wrapper
    margin: 0 0 0 145px
@media (max-width: 1364px)
  .not-found
    @include flexColumn
  .not-found__img
    position: absolute
    top: 460px
    left: 50%
    right: auto
    transform: translateX(-50%)
    margin: 0
  .not-found__wrapper
    @include borderBox
    margin: 153px auto 0 auto
    padding: 0 15px
@media (max-width: 767px)
  .not-found__wrapper
    @include borderBox
    margin: 140px auto 0 auto
</style>
