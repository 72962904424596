<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1685 14.8557L9.50133 14.4335L8.83345 14.8545L4.51583 17.576L5.67976 12.3502L5.83779 11.6406L5.29926 11.1524L1.35507 7.57639L6.49091 7.12023L7.25139 7.05268L7.53854 6.34526L9.49985 1.51332L11.4611 6.35597L11.7479 7.06424L12.5091 7.13184L17.6449 7.58801L13.7007 11.164L13.1622 11.6523L13.3202 12.3618L14.484 17.5868L10.1685 14.8557ZM17.7756 7.4695L17.7752 7.46989L17.7756 7.4695Z" fill="white" stroke="#2C2A29" stroke-width="2.5"/>
  </svg>
</template>

<script>
export default {
  name: 'headerBtnIconLk'
}
</script>
