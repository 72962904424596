import { axiosGet } from '@/service/axios'

export const citkp = {
  state () {
    return {
      header: '',
      content: '',
      events: '',
      projects: '',
      online: '',
      excursions: '',
      partners: '',
      successHistories: '',
      question1: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    stateEvents (state, data) {
      state.events = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    stateProjects (state, data) {
      state.projects = data
    },
    stateOnline (state, data) {
      state.online = data
    },
    stateExcursions (state, data) {
      state.excursions = data
    },
    statePartners (state, data) {
      state.partners = data
    },
    stateQuestion (state, data) {
      state.question1 = data
    }
  },
  actions: {
    getCitkpHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCitkpContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCitkpEvents ({ dispatch, commit }, data) {
      const act = data ? '?date=' + data : ''
      axiosGet('api/support-centers/citkp/events' + act, {}, (data) => {
        if (data.length !== 0) {
          commit('stateEvents', data)
        } else {
          commit('stateEvents', '')
        }
      })
    },
    getCitkpProjects ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/center-projects', {}, (data) => {
        if (data.length !== 0) {
          commit('stateProjects', data)
        } else {
          commit('stateProjects', '')
        }
      })
    },
    getCitkpOnline ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/online', {}, (data) => {
        if (data.length !== 0) {
          commit('stateOnline', data)
        } else {
          commit('stateOnline', '')
        }
      })
    },
    getCitkpSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getCitkpExcursions ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/excursions', {}, (data) => {
        if (data.length !== 0) {
          commit('stateExcursions', data)
        } else {
          commit('stateExcursions', '')
        }
      })
    },
    getCitkpPartners ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/partners', {}, (data) => {
        if (data.length !== 0) {
          commit('statePartners', data)
        } else {
          commit('statePartners', '')
        }
      })
    },
    getCitkpQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/citkp/question', {}, (data) => {
        if (data.length !== 0) {
          commit('stateQuestion', data)
        } else {
          commit('stateQuestion', '')
        }
      })
    }
  }
}
