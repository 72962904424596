import { axiosGet } from '@/service/axios'

export const cpe = {
  state () {
    return {
      header: '',
      content: '',
      products: '',
      online: '',
      successHistories: '',
      partners: '',
      question1: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    stateProducts (state, data) {
      state.products = data
    },
    stateOnline (state, data) {
      state.online = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    statePartners (state, data) {
      state.partners = data
    },
    stateQuestion (state, data) {
      state.question1 = data
    }
  },
  actions: {
    getCpeHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCpeContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCpeProducts ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/product', {}, (data) => {
        if (data.length !== 0) {
          commit('stateProducts', data)
        } else {
          commit('stateProducts', '')
        }
      })
    },
    getCpeOnline ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/online', {}, (data) => {
        if (data.length !== 0) {
          commit('stateOnline', data)
        } else {
          commit('stateOnline', '')
        }
      })
    },
    getCpeSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getCpePartners ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/partners', {}, (data) => {
        if (data.length !== 0) {
          commit('statePartners', data)
        } else {
          commit('statePartners', '')
        }
      })
    },
    getCpeQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/cpe/question', {}, (data) => {
        if (data.length !== 0) {
          commit('stateQuestion', data)
        } else {
          commit('stateQuestion', '')
        }
      })
    }
  }
}
