<template>
  <a class="hand-book-card" :href="link" target="_blank" v-if="link">
    <div class="hand-book-card__img-container">
      <img :src="host + img" v-if="img">
    </div>
    <span v-if="name">{{name}}</span>
  </a>
</template>

<script>
import { host } from '@/const/host'
export default {
  name: 'handBookCard',
  props: ['img', 'link', 'name'],
  data () {
    return {
      host
    }
  }
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.handbook__list-card
  margin: 0 0 40px 40px
.hand-book-card
  @include resetBtn
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  @include borderBox
  width: 273px
  border: 1px solid $black
  border-radius: 10px
  padding: 20px 10px
  img
    width: 100%
    height: 100%
    object-fit: contain
    margin: 0 auto
  span
    font-family: 'Jost', sans-serif
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 130%
    color: $black
.hand-book-card__img-container
  width: 100px
  height: 50px
  margin: 0 10px 0 0
  flex: 0 0 auto
@media (max-width: 1919px)
  .hand-book-card
    width: 295px
@media (max-width: 1364px)
  .handbook__list-card
    margin: 0 0 20px 40px
  .hand-book-card
    @include flexColumn
    @include flexJustifyContentCenter
    width: 216px
    margin: 0 0 20px 40px
    span
      text-align: center
  .hand-book-card__img-container
    margin: 0 0 5px 0
@media (max-width: 767px)
  .hand-book-card
    @include flexRowNoWrap
    justify-content: flex-start
    width: 100%
  .hand-book-card__img-container
    margin: 0 10px 0 0
</style>
