import { axiosGet } from '@/service/axios'
import router from '@/router'

let eventBuffer = ''

export const eventsArchive = {
  state () {
    return {
      content: ''
    }
  },
  mutations: {
    stateEventsArchiveItems (state, data) {
      state.content = data
    }
  },
  actions: {
    getEventsArchiveItemsAndPageNav ({ dispatch, commit }, data) {
      if (data) {
        const isAnd = Object.keys(router.currentRoute.value.query).length !== 0 ? '&' : '?'
        axiosGet(`api${router.currentRoute.value.fullPath}${isAnd}pageCurrent=${data}`, {}, (data) => {
          if (data.length !== 0) {
            const arr = [...eventBuffer.items, ...data.items]
            eventBuffer.pageNav = data.pageNav
            eventBuffer.items = arr
            commit('stateEventsArchiveItems', Object.create(eventBuffer))
          }
        })
      } else {
        axiosGet(`api${router.currentRoute.value.fullPath}`, {}, (data) => {
          if (data.length !== 0) {
            eventBuffer = data
            commit('stateEventsArchiveItems', data)
          } else {
            commit('stateEventsArchiveItems', '')
          }
        })
      }
    }
  }
}
