import { axiosGet } from '@/service/axios'
import router from '@/router'

export const newsDetail = {
  state () {
    return {
      content: ''
    }
  },
  mutations: {
    stateContent (state, data) {
      state.content = data
    }
  },
  actions: {
    getContent ({ dispatch, commit }) {
      axiosGet(`api/keep-up/news/${router.currentRoute.value.params.id}`, {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    }
  }
}
