import { axiosGet } from '@/service/axios'
import router from '@/router'
import axios from 'axios'
import { host } from '@/const/host'

export const constructorForm = {
  state () {
    return {
      openForm: false,
      content: '',
      id: '',
      idItem: '',
      error: ''
    }
  },
  mutations: {
    stateOpenForm (state, data) {
      state.openForm = data
    },
    stateFormContent (state, data) {
      state.content = data
    },
    stateFormId (state, data) {
      state.id = data
    },
    stateFormIdItem (state, data) {
      state.idItem = data
    },
    stateFormError (state, data) {
      state.error = data
    }
  },
  actions: {
    getFormsContent ({ dispatch, commit }, id) {
      axiosGet(`api/forms/${id}`, {}, (data) => {
        if (data.length !== 0) {
          commit('stateFormId', id)
          commit('stateFormContent', data)
        } else {
          commit('stateFormContent', '')
        }
      })
    },
    pushFormsContent ({ dispatch, commit }, dataRes) {
      axios.post(host + `api/handler-form/${dataRes.id}`, dataRes.data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(function (response) {
          document.querySelector('.loader').classList.add('disable')
          if (router.currentRoute.value.fullPath === '/favorites' && response.data.success) {
            localStorage.setItem('supportCard', JSON.stringify([]))
            commit('stateLk', false)
            dispatch('getFavoritesContent')
          }
          if (response.data.success) {
            commit('stateFormContent', response.data)
            commit('stateFormId', '')
            commit('stateFormIdItem', '')
            commit('stateFormError', '')
          } else {
            if (response.data.result.error) {
              commit('stateFormError', response.data.result.error)
              dispatch('getFormsContent', dataRes.id)
            }
          }
        })
        .catch((error) => {
          document.querySelector('.loader').classList.add('disable')
          if (error.toJSON().code === 'ERR_BAD_REQUEST') {
            router.push({
              path: '/404', name: 'NotFound', component: () => import('@/views/404')
            })
          }
        })
    }
  }
}
