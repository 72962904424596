<template>
  <a v-if="target" :href="url" :class="classCustom" target="_blank" class="btn">
    <span>{{text}}<component v-bind:is="iconName"></component></span>
    <span>{{text}}<component v-bind:is="iconName"></component></span>
  </a>
  <router-link v-if="url && !target" :to="url" :class="classCustom" :target="target" class="btn">
    <span>{{text}}<component v-bind:is="iconName"></component></span>
    <span>{{text}}<component v-bind:is="iconName"></component></span>
  </router-link>
  <button v-if="!url" class="btn" :class="classCustom" @click="$emit('btn-click')">
    <span>{{text}}<component v-bind:is="iconName"></component></span>
    <span>{{text}}<component v-bind:is="iconName"></component></span>
  </button>
</template>

<script>
import Calendar from '@/components/btn/btnIcon/btnIconCalendar'
import Plus from '@/components/btn/btnIcon/btnIconPlus'
import Back from '@/components/btn/btnIcon/btnIconArrowBack'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'btn',
  props: ['url', 'text', 'iconName', 'classCustom', 'target'],
  components: {
    Calendar,
    Plus,
    Back
  }
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.btn
  @include flexRowWrap
  @include flexAlignItemsCenter
  @include flexJustifyContentCenter
  @include resetBtn
  @include transition
  @include borderBox
  position: relative
  height: fit-content
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 14px
  text-align: center
  letter-spacing: 0.1em
  text-transform: uppercase
  color: $black
  cursor: pointer
  border: 1px solid $black
  border-radius: 60px
  padding: 19px 25px
  margin: auto 40px auto auto
  overflow: hidden
  span:nth-child(1)
    @include transition
    @include flexRowWrap
    @include flexAlignItemsCenter
    position: relative
  span:nth-child(2)
    @include transition
    @include flexRowWrap
    @include flexAlignItemsCenter
    position: absolute
    top: 100%
    color: $white
    & path
      @include transition
      fill: $white
    & line
      @include transition
      stroke: $white
  &:before
    @include transition
    position: absolute
    top: 100%
    width: 100%
    height: 100%
    left: 0
    background: $black
    content: ''
  svg
    @include transition
    margin: 0 0 0 14px
  & path
    @include transition
    fill: $black
  & line
    @include transition
    stroke: $black
  &:hover
    &:before
      top: 0
    span:nth-child(2)
      top: 50%
      transform: translateY(-50%)
    span:nth-child(1)
      transform: translateY(-30px)
      opacity: 0
  &.white
    border: 1px solid $white
    color: $white
    & path
      fill: $white
    & line
      stroke: $white
    &:before
      background: $white
    span:nth-child(2)
      color: $black
      & path
        fill: $black
      & line
        stroke: $black
  &.red
    border: 1px solid $black
    background: $red
    color: $white
    & path
      fill: $white
    & line
      stroke: $white
    &:before
      background: $white
    span:nth-child(2)
      color: $red
      & path
        fill: $white
      & line
        stroke: $white
  &.dark-blue
    border-color: $darkBlue
    color: $darkBlue
    &:before
      background: $darkBlue
      border-color: $darkBlue
  &.fs-18
    font-size: 18px
  &.disabled
    pointer-events: none
    opacity: 0.2
@media (max-width: 767px)
  .btn
    &:before
      display: none
    span:nth-child(2)
      display: none
    &:hover
      span:nth-child(1)
        transform: none
        opacity: 1
    span
      font-size: 14px
    svg
      margin: 0 0 0 5px
</style>
