import { createStore } from 'vuex'
import { header } from '@/store/storContainer/header/header'
import { footer } from '@/store/storContainer/footer/footer'
import { homePage } from '@/store/storContainer/homePage/homePage'
import { news } from '@/store/storContainer/news/news'
import { newsDetail } from '@/store/storContainer/news/newsDetail'
import { online } from '@/store/storContainer/online/online'
import { successHistories } from '@/store/storContainer/successHistories/successHistories'
import { successHistoriesDetail } from '@/store/storContainer/successHistories/successHistoriesDetail'
import { breadCrumbs } from '@/store/storContainer/breadCrumbs/breadCrumbs'
import { eventsDetail } from '@/store/storContainer/events/eventsDetail'
import { eventsArchiveDetail } from '@/store/storContainer/eventsArchive/eventsArchiveDetail'
import { documents } from '@/store/storContainer/documents/documents'
import { contacts } from '@/store/storContainer/contacts/contacts'
import { support } from '@/store/storContainer/support/support'
import { supportDetail } from '@/store/storContainer/support/supportDetail'
import { ciks } from '@/store/storContainer/ciks/ciks'
import { cop } from '@/store/storContainer/cop/cop'
import { cfp } from '@/store/storContainer/cfp/cfp'
import { cpe } from '@/store/storContainer/cpe/cpe'
import { citkp } from '@/store/storContainer/citkp/citkp'
import { ciss } from '@/store/storContainer/ciss/ciss'
import { handbook } from '@/store/storContainer/handbook/handbook'
import { search } from '@/store/storContainer/search/search'
import { staticPage } from '@/store/storContainer/static/static'
import { favorites } from '@/store/storContainer/favorites/favorites'
import { constructorForm } from '@/store/storContainer/constructorForm/constructorForm'
import { events } from '@/store/storContainer/events/events'
import { eventsArchive } from '@/store/storContainer/eventsArchive/eventsArchive'
import { knowledge } from '@/store/storContainer/knowledge/knowledge'
import { project } from '@/store/storContainer/project/project'
import { excursions } from '@/store/storContainer/excursions/excursions'
import { assistant } from '@/store/storContainer/assistant/assistant'
import { filterDate } from '@/store/storContainer/filterDate/filtrerDate'
import { supportClickCard } from '@/store/storContainer/support/supportClickCard'
import { generator } from '@/store/storContainer/generator/generator'
import { notFound } from '@/store/storContainer/notFound/notFound'
import { CallBackForm } from '@/store/storContainer/CallBackForm/CallBackForm'

export default createStore({
  modules: {
    header,
    footer,
    homePage,
    news,
    newsDetail,
    online,
    successHistories,
    successHistoriesDetail,
    breadCrumbs,
    events,
    eventsDetail,
    eventsArchive,
    eventsArchiveDetail,
    documents,
    contacts,
    support,
    supportDetail,
    ciks,
    cop,
    cfp,
    cpe,
    citkp,
    ciss,
    handbook,
    search,
    staticPage,
    favorites,
    constructorForm,
    knowledge,
    project,
    excursions,
    assistant,
    filterDate,
    supportClickCard,
    generator,
    notFound,
    CallBackForm
  }
})
