<template>
 <div class="footer__website-development-wrapper">
   <div class="footer__website-development">
     <a class="footer__website-development-link left" href="http://decent-design.ru/" target="_blank">
       <footerWebsiteDevelopmentDD/>
     </a>
     <i class="footer__website-development-hr"></i>
     <a class="footer__website-development-link right" href="https://recifra.ru/" target="_blank">
       <img :src="require('@/assets/img/svg/RC_Logo.svg')">
     </a>
   </div>
   <a class="footer__website-development-link kubok" href="https://recifra.ru/" target="_blank">
     <img :src="require('@/assets/img/svg/kubok.svg')">
   </a>
 </div>
</template>

<script>
import footerWebsiteDevelopmentDD
  from '@/components/footerApp/footerWebsiteDevelopment/footerWebsiteDevelopmentLogo/footerWebsiteDevelopmentDD'

export default {
  name: 'footerWebsiteDevelopment',
  components: {
    footerWebsiteDevelopmentDD
  }
}
</script>

<style lang="sass">
@import "../../../mixin"
@import "../../../variables"

.footer__website-development
  @include flexRowNoWrap
  @include flexAlignItemsCenter
.footer__website-development-link
  &.left
    height: 45px
  img
    width: 100%
    height: 47px
.footer__website-development-hr
  display: flex
  flex: 0 0 auto
  width: 2px
  background: $black
  margin: 0 16px
  height: 46px
@media (max-width: 1364px)
  .footer__website-development
    order: 2
@media (max-width: 767px)
  .footer__website-development
    margin: 0 auto
.kubok img
  height: 100px
</style>
