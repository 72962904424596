<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 4.5V3H16.25V4.5H8.75V3H8V4.5H3.5V21H21.5V4.5H17ZM20.75 20.25H4.25V9H20.75V20.25ZM20.75 8.25H4.25V5.25H8V6.75H8.75V5.25H16.25V6.75H17V5.25H20.75V8.25Z" fill="#2C2A29"/>
  </svg>
</template>

<script>
export default {
  name: 'btnIconCalendar'
}
</script>
