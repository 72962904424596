import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueHeadful from 'vue-headful'
import VueAwesomePaginate from 'vue-awesome-paginate'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import YmapPlugin from 'vue-yandex-maps'

const settings = {
  apiKey: '858d0a4a-5f9c-49a4-852a-2c8deb68bac2',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

const app = createApp(App)
  .use(store)
  .use(router)
  .use(YmapPlugin, settings)
  .use(VueAwesomePaginate)
  .use(PerfectScrollbar)
  .component('vue-headful', vueHeadful)
  .component('Datepicker', Datepicker)

app.directive('click-outside', {
  mounted (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

router.isReady().then(() => {
  app.mount('main')
})
