<template>
  <button class="header__btn-icon search-ico" v-if="!url">
    <component v-bind:is="iconName"></component>
    <span>{{text}}</span>
  </button>
</template>

<script>

import lk from '@/components/headerApp/headerBtnIcon/headerBtnIcons/headerBtnIconLk'
import search from '@/components/headerApp/headerBtnIcon/headerBtnIcons/headerBtnIconSearch'

export default {
  name: 'headerBtnIcon',
  props: ['url', 'iconName', 'text'],
  components: {
    lk,
    search
  },
  computed: {
    headerMenuBtn () {
      return this.$store.state.header.headerMenuBtn
    }
  }
}
</script>

<style lang="sass">
@import "../../../mixin"
@import "../../../variables"
.header__btn-icon
  @include resetBtn
  @include transition
  @include flexRowNoWrap
  //width: 16px
  height: 16px
  cursor: pointer
  &:hover
    path
      fill: $red
  span
    font-family: 'Jost', sans-serif
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 160%
    letter-spacing: 0.05em
    text-transform: uppercase
    color: #2C2A29
    margin: 0 0 0 5px
  svg
    //width: 100%
    height: 100%
    flex: 0 0 auto
    path
      @include transition
  &.lk
    margin: 0 0 0 63px
    &.active
      path
        fill: $red
  &.search-ico
    margin: 0
    margin-right: 60px
  &.disable
    opacity: 0
    pointer-events: none
@media (max-width: 1919px)
  .header__btn-icon.lk
    margin: 0 0 0 40px
@media (max-width: 1364px)
  .header__btn-icon
    display: none
</style>
