<template>
  <label class="event-filter-tag" v-if="type === 'toggle' || !type">
    <div class="event-filter-tag__wrap" :class="selected ? 'active' : ''">
      <span class="event-filter-tag__name" @click="click(value)">{{text}}</span>
      <i class="event-filter-tag__remove" @click="removeTag()">
        <span class="event-filter-tag__remove-line_top"></span>
        <span class="event-filter-tag__remove-line_bottom"></span>
      </i>
    </div>
  </label>
</template>

<script>
export default {
  name: 'filterTag',
  props: ['text', 'value', 'name', 'checked', 'type'],
  computed: {
    selected () {
      if (this.$props.value === this.$props.checked) {
        return true
      } else return false
    }
  },
  methods: {
    click (e) {
      this.$emit('customClick', e)
    },
    removeTag () {
      this.$emit('removeTag', '')
    }
  }
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.event-filter-tag
  @include flexRowWrap
  cursor: pointer
.event-filter-tag__name
  @include transition
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 160%
  letter-spacing: 0.08em
  color: $black
  z-index: 2
.event-filter-tag__wrap
  display: flex
  align-items: center
  gap: 10px
  border: 1px solid #7E8087
  border-radius: 5px
  padding: 5px 10px
.event-filter-tag__wrap.active
  background: $black
  border: 1px solid $black
  .event-filter-tag__remove
    display: flex
  .event-filter-tag__name
    color: $white
.event-filter-tag__remove
  display: none
  background: $white
  border-radius: 100%
  width: 18px
  height: 18px
  position: relative
  transform: rotate(45deg)
  z-index: 2
  .event-filter-tag__remove-line_top
    width: 2px
    background: $black
    position: absolute
    height: 9px
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
  .event-filter-tag__remove-line_bottom
    width: 9px
    height: 2px
    background: $black
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
</style>
