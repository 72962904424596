<template>
  <picture>
    <source v-if="srcWebp" :srcset="srcWebp" type="image/webp">
    <img :src="src" :alt="alt" loading="lazy" />
  </picture>
</template>

<script>
export default {
  name: 'webpImg',
  props: ['srcWebp', 'src', 'alt']
}
</script>

<style lang="sass">

</style>
