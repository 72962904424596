<template>
  <a class="btn-callback"  @click="click(idForm, idItem)" v-if="idForm">
    <p>
      {{text}}
    </p>
  </a>
</template>
<script>
export default {
  name: 'btnCallback',
  props: ['idForm', 'iconName', 'text', 'classCustom', 'idItem'],
  methods: {
    click (id, idItem) {
      if (String(id).indexOf('http') === -1) {
        this.$store.dispatch('getFormsContent', id)
        this.$store.commit('stateFormIdItem', idItem)
        this.$store.commit('stateOpenForm', true)
      } else {
        window.open(id, '_blank')
      }
    }
  }
}
</script>
<style lang="sass">
.btn-callback p
  margin: 0
.btn-callback
  height: 30px
  cursor: pointer
  color: #FFFFFF
  z-index: 20
  padding: 0
  background: linear-gradient(106.85deg, #E04E39 33.29%, #FFA899 111.46%)
  position: fixed
  top: 50%
  right: -123px
  box-shadow: 0px 17px 24px rgb(98 59 42 / 14%)
  border-radius: 10px 10px 0px 0px
  width: 275px
  display: flex
  text-align: center
  justify-content: center
  transform: rotate(270deg)
  transition: all .35s
  align-items: center
.btn-callback p
  color: #FFFFFF
</style>
