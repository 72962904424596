import { axiosGet } from '@/service/axios'

export const header = {
  state () {
    return {
      headerMenuBtn: false,
      logo: '',
      tel: '',
      link: '',
      menu: '',
      title: '',
      lk: false,
      count: 0
    }
  },
  mutations: {
    stateLk (state, data) {
      if (data) {
        state.lk = data
        if (localStorage.getItem('supportCard') && JSON.parse(localStorage.getItem('supportCard')).length !== 0) {
          state.count = JSON.parse(localStorage.getItem('supportCard')).length
        }
      } else {
        if (localStorage.getItem('supportCard') && JSON.parse(localStorage.getItem('supportCard')).length !== 0) {
          state.lk = true
          state.count = JSON.parse(localStorage.getItem('supportCard')).length
        } else {
          state.lk = false
          state.count = 0
        }
      }
    },
    stateInverseHeaderMenuBtn (state) {
      state.headerMenuBtn = !state.headerMenuBtn
    },
    stateDisableHeaderMenuBtn (state) {
      state.headerMenuBtn = false
    },
    stateActiveHeaderMenuBtn (state) {
      state.headerMenuBtn = true
    },
    stateLogo (state, data) {
      state.logo = data
    },
    stateTel (state, data) {
      state.tel = data
    },
    stateLink (state, data) {
      state.link = data
    },
    stateMenu (state, data) {
      state.menu = data
    },
    stateTitle (state, data) {
      state.title = data
    }
  },
  actions: {
    getHeaderLogo ({
      dispatch,
      commit
    }) {
      axiosGet('api/header/logo', {}, (data) => {
        if (data.length !== 0) {
          commit('stateLogo', data)
        } else {
          commit('stateLogo', '')
        }
      })
    },
    getHeaderTel ({
      dispatch,
      commit
    }) {
      axiosGet('api/header/tel', {}, (data) => {
        if (data.length !== 0) {
          commit('stateTel', data.tel)
        } else {
          commit('stateTel', '')
        }
      })
    },
    getHeaderLink ({
      dispatch,
      commit
    }) {
      axiosGet('api/header/link', {}, (data) => {
        if (data.length !== 0) {
          commit('stateLink', data)
        } else {
          commit('stateLink', '')
        }
      })
    },
    getHeaderMenu ({
      dispatch,
      commit
    }) {
      axiosGet('api/header/menu', {}, (data) => {
        if (data.length !== 0) {
          commit('stateMenu', data)
        } else {
          commit('stateMenu', '')
        }
      })
    }
  }
}
