<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5839 14.5748L12.8907 10.8817C12.8711 10.8621 12.8481 10.8484 12.8276 10.8301C13.5543 9.7278 13.9784 8.40828 13.9784 6.98927C13.9784 3.12918 10.8492 0 6.9892 0C3.12918 0 0 3.12918 0 6.9892C0 10.8491 3.12911 13.9784 6.98913 13.9784C8.40821 13.9784 9.72766 13.5543 10.83 12.8276C10.8483 12.848 10.8619 12.871 10.8815 12.8907L14.5748 16.5839C15.1296 17.1386 16.029 17.1386 16.5839 16.5839C17.1386 16.0291 17.1386 15.1296 16.5839 14.5748ZM6.9892 11.5555C4.46724 11.5555 2.42287 9.51108 2.42287 6.9892C2.42287 4.46724 4.46731 2.42287 6.9892 2.42287C9.51102 2.42287 11.5555 4.46731 11.5555 6.9892C11.5555 9.51108 9.51102 11.5555 6.9892 11.5555Z" fill="#2C2A29"/>
  </svg>
</template>

<script>
export default {
  name: 'headerBtnIconSearch'
}
</script>
