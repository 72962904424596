import { axiosPost } from '@/service/axios'

export const generator = {
  state () {
    return {
      formData: ''
    }
  },
  mutations: {
    stateGenerator (state, data) {
      state.formData = data
    }
  },
  actions: {
    postGenerator ({ dispatch, commit }, dataForm) {
      axiosPost('api/generator', dataForm, (data) => {
        if (data.length !== 0) {
          commit('stateGenerator', data)
        } else {
          commit('stateGenerator', '')
        }
      })
    }
  }
}
