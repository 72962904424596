import { axiosGet } from '@/service/axios'

export const cfp = {
  state () {
    return {
      header: '',
      content: '',
      tags: '',
      centerPrograms: '',
      successHistories: '',
      question1: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    stateTags (state, data) {
      state.tags = data
    },
    stateCenterPrograms (state, data) {
      state.centerPrograms = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    stateQuestion (state, data) {
      state.question1 = data
    }
  },
  actions: {
    getCfpHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCfpContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCfpTags ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp/tags', {}, (data) => {
        if (data.length !== 0) {
          commit('stateTags', data)
        } else {
          commit('stateTags', '')
        }
      })
    },
    getCfpCenterPrograms ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp/programs', {}, (data) => {
        if (data.length !== 0) {
          commit('stateCenterPrograms', data)
        } else {
          commit('stateCenterPrograms', '')
        }
      })
    },
    getCfpSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getCfpQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/cfp/question', {}, (data) => {
        if (data.length !== 0) {
          commit('stateQuestion', data)
        } else {
          commit('stateQuestion', '')
        }
      })
    }
  }
}
