<template>
  <a class="footer__btn-icon" :href="url" target="_blank">
    <slot></slot>
  </a>
</template>

<script>

export default {
  name: 'footerBtnIcon',
  props: ['url']
}
</script>

<style lang="sass">
@import "../../../mixin"
@import "../../../variables"
.footer__btn-icon
  display: flex
  height: max-content
  path, circle
    @include transition
  &:hover
    path, circle
      fill: $red
</style>
