<template>
  <vue-headful
    title="Бизнес югры"
  />
  <headerApp v-if="!getNotFound" />
  <div class="container breadcrumbs-title" :class="getRoute.indexOf('support-centers') >= 0 ? 'support-centers__title' : ''" v-if="getRoutePath !== '/' && !getNotFound && getRoutePath !== '/favorites'">
    <breadcrumbs :class="getRoute.indexOf('support-centers') >= 0 ? 'support-centers' : ''" />
    <div class="title-btn" :class="{'news-detail__container container': getRouteParams === '/keep-up/news/:id', 'eventes-archive__space': getRoutePath === '/keep-up/events' && getItems.formId}" v-if="getRouteParams !== '/keep-up/events/:id' && getRouteParams !== '/trainers/:id' && getRouteParams !== '/keep-up/success-histories/:id' && getRouteParams !== '/knowledge/:id'">
      <button class="btn-back-page" @click="clickBackPage(getBackPage)" v-if="getBackPage">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2766 22.875L28 33.375L25.3191 36L10 21L25.3191 6L28 8.625L17.2766 19.125L15.3617 21L17.2766 22.875Z" fill="#7E8087"/>
        </svg>
      </button>
      <h1  v-if="getRouteParams !== '/assistant'">{{getTitle}}</h1>
      <btn-get-form v-if="getRoutePath === '/keep-up/events' && getItems.formId" class-custom="min event-list__filter-btn" text="подписаться на анонсы" :id-form="getItems.formId"></btn-get-form>
    </div>
  </div>
  <constructorForm v-if="!getNotFound" />
  <router-view v-if="!getNotFound" :class="[getRoutePath !== '/' && getRoutePath !== '/favorites' ? 'no-main-page' : '', getRouteParams === '/keep-up/events/:id' ? 'overflow-off' : '']" />
  <NotFound v-if="getNotFound"></NotFound>
  <footerApp :class="getRouteParams !== '/assistant' && getRouteParams !== '/support' ? 'footer__margin' : ''" v-if="!getNotFound && getRouteParams !== '/assistant'" />
  <BtnCallback text="Остались вопросы?" :id-form="getCallBackForm"/>
</template>

<script>
import headerApp from '@/components/headerApp/headerApp'
import footerApp from '@/components/footerApp/footerApp'
import breadcrumbs from '@/components/breadcrumbs/breadcrumbs'
import router from '@/router'
import constructorForm from '@/components/constructorForm/constructorForm'
import NotFound from '@/views/404.vue'
import BtnGetForm from '@/components/btnGetForm/btnGetForm.vue'
import BtnCallback from '@/components/btnCallback/btnCallback.vue'
import { canonicalLinks } from '@/service/canonicalLinks'

export default {
  components: {
    NotFound,
    headerApp,
    footerApp,
    breadcrumbs,
    constructorForm,
    BtnGetForm,
    BtnCallback
  },
  computed: {
    getNotFound () {
      return this.$store.state.notFound.check
    },
    getTitle () {
      return this.$store.state.header.title
    },
    getRoute () {
      return router.currentRoute.value.fullPath
    },
    getRoutePath () {
      return router.currentRoute.value.path
    },
    getRouteParams () {
      return router.currentRoute.value.matched[0].path
    },
    getBackPage () {
      return this.$store.state.supportClickCard.url
    },
    getItems () {
      this.$store.commit('stateTitle', this.$store.state.events.content.title)
      return this.$store.state.events.content
    },
    getCallBackForm () {
      return this.$store.state.CallBackForm.formId
    }
  },
  mounted () {
    this.$store.dispatch('getCallBackForm')
  },
  methods: {
    clickBackPage (url) {
      router.push(url)
      this.$store.commit('stateSupportClickCard', '')
    }
  },
  beforeCreate () {
    canonicalLinks()
    if (router.currentRoute.value.path !== '/' && router.currentRoute.value.path.slice(-1) === '/' && router.currentRoute.value.path !== '/special-page') {
      const param = router.currentRoute.value.fullPath.replace(router.currentRoute.value.path, '')
      document.location.href = router.currentRoute.value.path.replace(/\/+$/, '') + param
    }
    if (document.querySelector('title').innerHTML === '404' && router.currentRoute.value.path !== '/special-page') {
      this.$store.commit('mutationNotFound', true)
    }
  },
  watch: {
    '$route' (to, from) {
      canonicalLinks()
      if (router.currentRoute.value.path !== '/' && router.currentRoute.value.path.slice(-1) === '/' && router.currentRoute.value.path !== '/special-page') {
        const param = router.currentRoute.value.fullPath.replace(router.currentRoute.value.path, '')
        document.location.href = router.currentRoute.value.path.replace(/\/+$/, '') + param
      }
      if (from.path !== '/assistant' && from.path !== '/support' && from.path !== '/special-page') {
        this.$store.commit('stateSupportClickCard', '')
      }
      this.$store.commit('stateDisableHeaderMenuBtn')
      this.$store.commit('stateHeaderSearch', '')
      window.scrollTo({
        top: 0
      })
    }
  }
}
</script>

<style lang="sass">
@import "includeFont"
@import "variables"
@import "mixin"

html, body, main
  @include flexColumn
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  background: $white
  scroll-behavior: smooth

body.open-menu
  overflow: hidden

main > .content
  @include flexColumn
  padding: 140px 0 60px 0
  flex: 1 0 auto
  overflow: hidden
  &.no-main-page
    padding: 0 0 60px 0
.stick
  position: sticky
  top: 140px
.container
  @include flexColumn
  @include borderBox
  width: 100%
  max-width: 1840px
  margin: 0 auto
.red-text
  color: $red !important
.btn-back-page
  @include resetBtn
  margin: 0 40px 0 0
  cursor: pointer
  path
    @include transition
  &:hover
    path
      fill: $black
.swiper-wrapper
  margin: 0 0 40px 0 !important
.btn-margin-20-0
  width: max-content
  margin: 20px 0
.swiper-scrollbar
  border-bottom: 1px solid $darkGray
  background: none !important
  border-radius: 0 !important
  width: 100% !important
  left: 0 !important
.swiper-scrollbar-drag
  background: $black !important
  border-radius: 0 !important

.title-btn
  @include flexRowNoWrap
.ps__thumb-y
  background-color: $black !important
.overflow-off
  overflow: visible !important
.flex-wrap
  @include flexRowWrap
h1
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 60px
  line-height: 110%
  letter-spacing: 0.03em
  color: $black
  margin: 0

h2
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 50px
  line-height: 110%
  letter-spacing: 0.03em
  color: $black
  margin: 0
h3
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 40px
  line-height: 150%
  letter-spacing: 0.03em
  margin: 0 0 20px 0
h4
  font-family: 'Jost', sans-serif
  font-weight: 400
  font-size: 25px
  line-height: 36px
  letter-spacing: 0.03em
  color: $black
  margin: 0
p
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 170%
  letter-spacing: 0.03em
  color: $black

b
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 150%
  letter-spacing: 0.03em
  color: $black
  p
    display: inline-flex
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 170%
    letter-spacing: 0.03em
    color: $black
.date-detail
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 600
  font-size: 12px
  line-height: 130%
  letter-spacing: 0.03em
  text-transform: uppercase
  color: $darkGray
  margin: 20px 0 100px 0
ul
  padding: 0
  & > li
    font-family: 'Raleway', sans-serif
    font-weight: 400
    font-size: 20px
    line-height: 170%
    letter-spacing: 0.03em
    color: $black
    list-style-type: none
    margin: 0 0 20px 0
    &:before
      position: relative
      display: inline-block
      content: ''
      width: 13px
      height: 13px
      flex: 0 0 auto
      border-radius: 100%
      border: 2px solid $red
      margin: 7px 14px 0 0
    &:last-child
      margin: 0
  &.min
    li:before
      width: 8px
      height: 8px
      margin: 5px 10px 0 0
  ol
    margin: 0 0 0 20px
    & > li:before
      border: none
  ul
    margin: 10px 0 0 20px
ol
  padding: 0
  counter-reset: count
  list-style: none
  & > li
    font-family: 'Raleway', sans-serif
    font-weight: 400
    font-size: 20px
    line-height: 170%
    letter-spacing: 0.03em
    color: $black
    margin: 0 0 20px 0
    &:before
      position: relative
      display: inline-block
      counter-increment: count
      content: counters(count,".") ". "
      margin: 0 10px 0 0
  ol
    margin: 10px 0 0 20px
  ul
    margin: 0 0 0 20px
    & > li:before
      content: ''
      margin: 7px 14px 0 0
a:not([class])
  @include transition
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 170%
  letter-spacing: 0.03em
  text-decoration: none
  color: $black
  width: max-content
  border-bottom: 2px solid $black20
  &:hover
    background: $lightGray
    border-bottom: 2px solid $lightGray
  &:visited
    border-bottom: 2px solid $darkGray
.breadcrumbs-title
  margin: 0 auto 40px auto
.centres__events
  margin: 0 0 60px 0
.centres__events-filter
  margin: 0 0 40px 0
.centres__events-swiper
  width: 100%
  .swiper-wrapper
    margin: 10px 0 40px 0 !important
.centres__events-swiper-slide
  width: 430px !important
  margin: 0 26px 0 10px
.centres__project-swiper
  width: 100%
  margin: 0 0 60px 0
.centres__project-swiper-slide
  width: 336px !important
  margin: 0 40px 0 0
  &:last-child
    margin: 0 0 0 0
.centres__excursion-swiper
  width: 100%
  margin: 0 0 60px 0
.centres__excursion-swiper-slide
  width: auto !important
  margin: 0 38px 0 0
.centres__partner-swiper
  width: 100%
  margin: 0 0 100px 0
.centres__partner-swiper-slide
  width: 336px !important
  margin: 0 20px 0 0
.centres__history
  margin: 0 0 60px 0
.centres__history-swiper-slide
  width: auto !important
  margin: 0 40px 0 0
.centres__tags
  @include flexRowWrap
  margin: 0 0 40px -40px
  .tag-text-stroke
    margin: 0 0 0 40px
.centers__h2
  margin: 0 0 60px 0
.center-text
  margin: 100px auto 0 auto
.no-events
  margin: 0 0 40px 0
#atcb-bgoverlay
  backdrop-filter: none !important
  background-color: transparent !important
  cursor: default !important
.atcb-list
  border-radius: 10px !important
  box-shadow: none !important
  font-family: 'Jost', sans-serif !important
  font-style: normal !important
  font-weight: 400 !important
  font-size: 18px !important
  overflow: hidden
  border: 1px solid $black !important
.atcb-list-item
  background-color: #fff !important
  &:not(:last-child)
    border-bottom: 1px solid $black !important
.flex-by__project-card
  @include flexRowWrap
  margin: 0 0 0 -40px
.handbook__list
  @include flexRowWrap
  margin: 0 0 0 -40px
.project__project-card
  margin: 0 0 40px 40px
.eventes-archive__space
  justify-content: space-between
@media (max-width: 1919px)
  .flex-by__project-card
    margin: 0 0 0 -30px
  .project__project-card
    width: 302px !important
    margin: 0 0 40px 30px
  main > .content
    padding: 160px 0 100px 0
    &.no-main-page
      padding: 0 0 100px 0
  .container
    max-width: 1301px
  ul
    li
      font-size: 18px
  h1
    font-size: 58px
  .centres__history-swiper-slide
    margin: 0 40px 0 0
@media (max-width: 1364px)
  .eventes-archive__space
    flex-wrap: wrap
    gap: 20px
  .flex-by__project-card
    margin: 0 0 0 -55px
  .project__project-card
    width: 336px !important
    margin: 0 0 40px 55px
  main > .content
    padding: 120px 0 80px 0
    &.no-main-page
      padding: 0 0 80px 0
  .container
    max-width: 730px
  h1
    font-size: 48px
  h2
    font-size: 48px
  .breadcrumbs-title
    margin: 100px auto 20px auto
  .support-centers__title
    margin: 158px auto 20px auto
  .centres__history-swiper-slide
    margin: 0 40px 0 0
@media (max-width: 992px)
  .support-centers__title
    margin: 100px auto 20px auto
@media (max-width: 767px)
  .flex-by__project-card
    @include flexRowWrap
    margin: 0 0 0 0
  .project__project-card
    width: 100% !important
    margin: 0 0 40px 0
  .no-events
    font-size: 28px
  main > .content
    padding: 80px 0 75px 0
    &.no-main-page
      padding: 0 0 75px 0
  .container
    padding: 0 15px
    max-width: 100%
  h1
    font-size: 32px
  h2
    font-size: 26px
</style>
