import { axiosGet } from '@/service/axios'

export const ciks = {
  state () {
    return {
      header: '',
      content: '',
      tags: '',
      online: '',
      partners: '',
      successHistories: '',
      question1: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    stateTags (state, data) {
      state.tags = data
    },
    stateOnline (state, data) {
      state.online = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    statePartners (state, data) {
      state.partners = data
    },
    stateQuestion (state, data) {
      state.question1 = data
    }
  },
  actions: {
    getCiksHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCiksContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCiksSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getCiksTags ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/tags', {}, (data) => {
        if (data.length !== 0) {
          commit('stateTags', data)
        } else {
          commit('stateTags', '')
        }
      })
    },
    getCiksOnline ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/online', {}, (data) => {
        if (data.length !== 0) {
          commit('stateOnline', data)
        } else {
          commit('stateOnline', '')
        }
      })
    },
    getCiksPartners ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/partners', {}, (data) => {
        if (data.length !== 0) {
          commit('statePartners', data)
        } else {
          commit('statePartners', '')
        }
      })
    },
    getCiksQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/ciks/question', {}, (data) => {
        if (data.length !== 0) {
          commit('stateQuestion', data)
        } else {
          commit('stateQuestion', '')
        }
      })
    }
  }
}
