import { axiosGet } from '@/service/axios'

export const cop = {
  state () {
    return {
      header: '',
      content: '',
      events: '',
      centerProjects: '',
      successHistories: '',
      partners: '',
      question1: ''
    }
  },
  mutations: {
    stateHeader (state, data) {
      state.header = data
    },
    stateContent (state, data) {
      state.content = data
    },
    stateEvents (state, data) {
      state.events = data
    },
    stateCenterProjects (state, data) {
      state.centerProjects = data
    },
    stateSuccessHistories (state, data) {
      state.successHistories = data
    },
    statePartners (state, data) {
      state.partners = data
    },
    stateQuestion (state, data) {
      state.question1 = data
    }
  },
  actions: {
    getCopHeader ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop/header', {}, (data) => {
        if (data.length !== 0) {
          commit('stateHeader', data)
        } else {
          commit('stateHeader', '')
        }
      })
    },
    getCopContent ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop', {}, (data) => {
        if (data.length !== 0) {
          commit('stateContent', data)
        } else {
          commit('stateContent', '')
        }
      })
    },
    getCopEvents ({ dispatch, commit }, data) {
      const act = data ? '?date=' + data : ''
      axiosGet('api/support-centers/cop/events' + act, {}, (data) => {
        if (data.length !== 0) {
          commit('stateEvents', data)
        } else {
          commit('stateEvents', '')
        }
      })
    },
    getCopCenterProjects ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop/center-projects', {}, (data) => {
        if (data.length !== 0) {
          commit('stateCenterProjects', data)
        } else {
          commit('stateCenterProjects', '')
        }
      })
    },
    getCopSuccessHistories ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop/success-histories', {}, (data) => {
        if (data.length !== 0) {
          commit('stateSuccessHistories', data)
        } else {
          commit('stateSuccessHistories', '')
        }
      })
    },
    getCopPartners ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop/partners', {}, (data) => {
        if (data.length !== 0) {
          commit('statePartners', data)
        } else {
          commit('statePartners', '')
        }
      })
    },
    getCopQuestion ({ dispatch, commit }) {
      axiosGet('api/support-centers/cop/question', {}, (data) => {
        if (data.length !== 0) {
          commit('stateQuestion', data)
        } else {
          commit('stateQuestion', '')
        }
      })
    }
  }
}
