<template>
  <a :href="url" class="search-card" target="_blank" v-if="url && !type">
    <p class="search-card__title" v-if="title" v-html="title"></p>
    <span class="search-card__date" v-if="date">Изменен: {{ date }}</span>
    <div class="search-breadcrumbs" v-if="breadcrumbs">
      <span class="search-breadcrumbs__path">Путь:</span>
      <router-link class="search-breadcrumbs__link" v-for="(item, key) in breadcrumbs" :key="key" :to="item.url ? item.url : '/'">{{ item.text }}
        <span class="search-breadcrumbs__separator">/</span>
      </router-link>
    </div>
  </a>
  <router-link :to="url" class="search-card" v-if="url && type">
    <p class="search-card__title" v-if="title" v-html="title"></p>
    <span class="search-card__date" v-if="date">Изменен: {{ date }}</span>
    <div class="search-breadcrumbs" v-if="breadcrumbs">
      <span class="search-breadcrumbs__path">Путь:</span>
      <router-link class="search-breadcrumbs__link" v-for="(item, key) in breadcrumbs" :key="key" :to="item.url ? item.url : '/'">{{ item.text }}
        <span class="search-breadcrumbs__separator">/</span>
      </router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'searchCard',
  props: ['date', 'title', 'url', 'breadcrumbs', 'type']
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.search-card
  @include resetBtn
  @include borderBox
  border: 1px solid $darkGray
  border-radius: 10px
  background: $white
  padding: 20px 40px
  margin: 20px 0 0 0
.search-card__title
  margin: 0 0 20px 0
.search-card__date
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 130%
  letter-spacing: 0.03em
  color: $black
.search-breadcrumbs__path
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 130%
  letter-spacing: 0.03em
  color: $black
  margin: 0 10px 0 0
.search-breadcrumbs
  @include flexRowWrap
  margin: 20px 0 0 0
  &.support-centers
    margin: 200px 0 25px 0
.search-breadcrumbs__link
  @include resetBtn
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 160%
  color: $black
  margin: 0 0 15px 0
  &:last-child
    color: $black20
    pointer-events: none
    cursor: default
    .search-breadcrumbs__separator
      display: none
.search-breadcrumbs__separator
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 160%
  color: $black
  margin: 0 15px
@media (max-width: 1364px)
  .search-breadcrumbs
    display: none
</style>
