<template>
  <a class="docs" :href="host + url" target="_blank" v-if="url">
    <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.414 0H0V19H2V1.998H16V7.999H22V30H16V32H24V5.584L18.414 0ZM21.586 6L17.998 2.413V6H21.586Z" fill="#2C2A29"/>
      <line x1="4" y1="15" x2="20" y2="15" stroke="#2C2A29" stroke-width="2"/>
      <line x1="4" y1="11" x2="13" y2="11" stroke="#2C2A29" stroke-width="2"/>
    </svg>
    <span class="docs__ext">{{format}}</span>
    <p class="docs__title">{{title}}</p>
  </a>
</template>

<script>
import { host } from '@/const/host'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'docs',
  props: ['url', 'title', 'format'],
  data () {
    return {
      host
    }
  }
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.docs
  @include flexRowNoWrap
  @include resetBtn
  position: relative
  margin: 0 0 20px 0
  &:hover
    .docs__title
      background: $lightGray
      border-bottom: 2px solid $lightGray
  svg
    flex: 0 0 auto
.docs__ext
  position: absolute
  top: 21px
  left: 0
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 700
  font-size: 7px
  line-height: 160%
  text-transform: uppercase
  text-decoration: none
  color: $black
.docs__title
  @include transition
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 170%
  letter-spacing: 0.03em
  color: $black
  margin: 0 0 0 10px
  border-bottom: 2px solid $black20
</style>
