<template>
  <div class="constructor-form" :class="[getContent.success ? 'success' : '', getContent.big_form === 'Y' ?  'big' : '']" v-if="getOpenForm">
    <div class="constructor-form__body">
      <button class="constructor-form__close" @click="close">
        <svg width="37" height="33" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="2.70711" y1="2" x2="32.4056" y2="31.6985" stroke="#2C2A29" stroke-linecap="square"/>
          <line x1="4" y1="31.6985" x2="33.6985" y2="1.99999" stroke="#2C2A29" stroke-linecap="square"/>
        </svg>
      </button>
      <div v-if="this.$route.path === '/support-centers/ciks'" class="constructor-form__body-header">
        <a class="constructor-form__body-header-btn" target="_blank" href="https://xn--l1agf.xn--p1ai/services/reg-support-map/">
          <span>Подать заявку на цифровой платформе</span>
          <div class="constructor-form__body-header-logo" :style="'background-image: url('+ require('@/assets/img/jpgPng/logoForm.png') +')'"></div>
        </a>
        <h2 class="constructor-form__body-header-title" v-if="getContent.title">или заполните форму подачи заявки</h2>
      </div>
      <h2 class="constructor-form__body-title" v-if="getContent.title === 'Заказать обратный звонок' && this.$route.path !== '/support-centers/ciks'" style="text-transform: none;">Есть вопрос? <br><br> Задайте его через платформу <a class="constructor-form__body-title" href="https://form86.admhmao.ru/crm_form_2bkw6/" target="_blank" style="text-decoration: none; border-bottom: 2px solid #D5D4D4; text-transform: none"><br>«ЦУР-Бизнес»</a> <br><br>Или закажите обратный звонок</h2>
      <h2 class="constructor-form__body-title" v-if="getContent.title && getContent.title !== 'Заказать обратный звонок' && this.$route.path !== '/support-centers/ciks'">{{ getContent.title }}</h2>
      <renderContent v-if="getContent.success" :render="getContent.success"/>
      <btn class-custom="constructor-form__success" v-if="getContent.success" @btn-click="close" text="Хорошо"/>
      <form class="constructor-form__body-form" enctype="multipart/form-data" @submit.prevent="onSubmit" v-if="getContent.title">
          <div class="constructor-form__tags-container" v-if="toggle">
            <filterTag @customClick="getTags" :text="item.name" :value="item.id" :name="toggle.name" :key="key" :checked="key === 0 ? item.id : ''" :type="toggle.type" v-for="(item, key) in toggle.list" />
          </div>
        <inputCustom
          classCustom="constructor-form__input"
          :type="item.type"
          :placeholder="item.placeholder"
          :name="item.name"
          :items="item.list"
          :required="item.required !== 'N'"
          :multiple="item.multiple !== 'N'"
          :type-input="item.id"
          :filterInput="filterInput"
          v-for="(item, key) in getContent.fields"
          :key="key"
          :error-msg="Object.keys(getError).includes(item.name) ? getError[item.name] : ''"
          :dynamicRef="`constructorForm${key}`"/>
        <div class="captcha-container">
          <checkBox class="constructor-form__person-data" @checked="checked" name="personData"/>
          <input name="captcha_code" :value="getContent.capthcha.fields.captcha_code.value" type="text" hidden
                 v-if="getContent.capthcha.fields && getContent.capthcha.fields.captcha_code">
          <div class="captcha-container__captcha">
            <img loading="lazy" class="captchaImg" :src="host + getContent.capthcha.captchaImg" v-if="getContent.capthcha.captchaImg">
            <button class="refresh-captchaImg" @click="refreshForm">
              <svg fill="#2C2A29" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                   id="update" class="icon glyph">
                <path
                  d="M5,12A7,7,0,0,1,16.89,7H14a1,1,0,0,0,0,2h5.08A1,1,0,0,0,20,8V3a1,1,0,0,0-2,0V5.32A9,9,0,0,0,3,12a1,1,0,0,0,2,0Z"></path>
                <path
                  d="M20,11a1,1,0,0,0-1,1A7,7,0,0,1,7.11,17H10a1,1,0,0,0,0-2H4.92A1,1,0,0,0,4,16v5a1,1,0,0,0,2,0V18.68A9,9,0,0,0,21,12,1,1,0,0,0,20,11Z"></path>
              </svg>
            </button>
          </div>
          <inputCustom
            classCustom="constructor-form__input input-captcha"
            type="text"
            placeholder="Введите русские заглавные буквы и цифры с картинки"
            name="captcha_word"
            :required="true"
          />
          <p class="red-text" v-if="getError && getError.captcha_word">{{ getError.captcha_word }}</p>
          <p class="red-text" v-if="getError && getError.aB">{{ getError.aB }}</p>
          <input name="aB" hidden>
          <btn :class-custom="['constructor-form__send', !submit ? 'disabled' : '']" text="отправить"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import btn from '@/components/btn/btn'
import checkBox from '@/components/checkBox/checkBox'
import inputCustom from '@/components/inputCustom/inputCustom'
import renderContent from '@/components/renderContent/renderContent.vue'
import { host } from '@/const/host'
import filterTag from '@/components/filterTag/filterTag.vue'

export default {
  name: 'constructorForm',
  components: {
    btn,
    checkBox,
    inputCustom,
    renderContent,
    filterTag
  },
  data () {
    return {
      submit: false,
      host,
      filterInput: '',
      toggle: ''
    }
  },
  methods: {
    checked (e) {
      this.submit = e
    },
    getTags (e) {
      this.filterInput = e
    },
    close () {
      this.submit = false
      this.$store.commit('stateFormContent', '')
      this.$store.commit('stateOpenForm', false)
      this.$store.commit('stateFormId', '')
      this.$store.commit('stateFormIdItem', '')
      this.$store.commit('stateFormError', '')
      this.filterInput = ''
      this.toggle = ''
    },
    onSubmit (e) {
      const formData = new FormData(e.target)
      const form = Object.fromEntries(formData)
      form.ids = this.getIdItem
      this.$store.dispatch('pushFormsContent', {
        id: this.getId,
        data: form
      })
      this.filterInput = ''
      this.toggle = ''
    },
    refreshForm (e) {
      e.preventDefault()
      this.$store.dispatch('getFormsContent', this.getId)
    }
  },
  computed: {
    getId () {
      return this.$store.state.constructorForm.id
    },
    getIdItem () {
      return this.$store.state.constructorForm.idItem
    },
    getContent () {
      const data = this.$store.state.constructorForm.content.fields
      if (data) {
        data.forEach((el) => {
          if (el.type === 'toggle') {
            this.toggle = el
            this.filterInput = el.list[0].id
          }
        })
      }
      return this.$store.state.constructorForm.content
    },
    getOpenForm () {
      return this.$store.state.constructorForm.openForm
    },
    getError () {
      return this.$store.state.constructorForm.error
    }
  }
}
</script>

<style lang="sass">
@import '../../mixin'
@import '../../variables'
.constructor-form
  @include borderBox
  position: fixed
  display: flex
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: $white
  z-index: 1000
  transition: 0.35s
  overflow: scroll
  padding: 40px
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
  &.success
    background: rgba(255, 255, 255, 0.6)
    .constructor-form__body
      background: $lightGray
      padding: 40px
    .constructor-form__close
      margin: 0 0 20px auto
  &.big
    .constructor-form__body
      max-width: 100%
    .constructor-form__body-form
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between
    .input-custom, .select-form
      width: 48%
      height: max-content
    .captcha-container
      width: 48%
      align-items: flex-start
      flex-direction: column
  .event-filter-tag
    margin: 0 10px 10px 0
.captcha-container
  width: 100%
  align-items: flex-start !important
  flex-direction: column
.input-captcha
  width: 100% !important
.captcha-container__captcha
  display: flex
  align-items: center
.constructor-form__body
  @include borderBox
  transition: 0.35s
  display: flex
  flex-direction: column
  align-items: flex-start
  max-width: 854px
  width: 100%
  background: $white
  border: 1px solid $darkGray
  border-radius: 10px
  padding: 45px 133px 111px 133px
  margin: auto

.constructor-form__body-title
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 25px
  line-height: 150%
  letter-spacing: 0.1em
  text-transform: uppercase
  color: $black

.constructor-form__close
  @include resetBtn
  cursor: pointer
  margin: -10px -80px 32px auto

.refresh-captchaImg
  @include resetBtn
  width: 24px
  height: 24px
  margin: 0 0 0 20px
  cursor: pointer

.captcha-container
  display: flex
  align-items: center
  margin: 20px 0 0 0

.constructor-form__send
  margin: 20px auto 0 auto

.constructor-form__person-data
  margin: 20px 0

.constructor-form__input
  margin: 20px 0 0 0

.constructor-form__body-form
  width: 100%

.constructor-form__success
  margin: 20px 0 0 0
.constructor-form__tags-container
  @include flexRowWrap
  margin: 10px 0
.constructor-form__tags-container-title
  width: 100%
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 150%
  letter-spacing: 0.1em
  text-transform: uppercase
  color: $black

.constructor-form__body-header-btn
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap
  gap: 20px
  padding: 20px 10px
  background: #494c91
  box-sizing: border-box
  text-decoration: none
  font-family: "Jost", sans-serif
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 150%
  letter-spacing: 0.1em
  color: $white
  margin-bottom: 20px
  span
    text-align: center

.constructor-form__body-header-logo
  width: 148px
  height: 33px
  background-size: cover
  background-repeat: no-repeat

.constructor-form__body-header-title
  font-size: 18px
  color: $darkGray
  text-align: center

@media (max-width: 1100px)
  .constructor-form.big
    .constructor-form__body-form
      display: flex
      flex-direction: column
      flex-wrap: wrap
      justify-content: flex-start
    .input-custom, .select-form, .captcha-container
      width: 100%
@media (max-width: 767px)
  .constructor-form
    padding: 15px
  .constructor-form__body
    padding: 20px
  .constructor-form__close
    margin: -10px 0 10px auto
</style>
