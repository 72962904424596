<template>
  <div class="select-form" :class="errorMsg ? 'error' : ''" v-click-outside="closeSelect" v-if="items">
    <span @click="openSelect" class="select-form__result">{{nameSelected && nameSelected.length !== 0 ? nameSelected.toLocaleString() : nameSelect ? nameSelect : placeholder}}</span>
    <svg @click="openSelect" :class="open ? 'active' : ''" class="select-form__arrow" width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 6.55319L1.75 8.77179e-07L-4.09213e-07 1.6383L10 11L20 1.6383L18.25 1.55941e-07L11.25 6.55319L10 7.7234L8.75 6.55319Z" fill="#2C2A29"/>
    </svg>
    <select :name="name" :required="required" v-model="selected" :multiple="multiple" class="select-form__select">
      <option :value="item.id" v-for="(item, key) in items" :key="key">{{item.fio ? item.fio : item.name}}</option>
    </select>
    <perfect-scrollbar class="select-form__list" :hidden="!open">
      <search class="select-form__search" @customInput="getSearch" :placeholder="searchPlaceholder" v-if="search" />
      <div v-if="!itemsSearch">
        <span class="select-form__list-name" :class="selected.indexOf(item.id) !== -1 ? 'selected' : ''" v-for="(item, key) in items" :key="key" @click="click(item.id, item.fio ? item.fio : item.name)">{{item.fio ? item.fio : item.name}}</span>
      </div>
      <div v-if="itemsSearch">
        <span class="select-form__list-name" :class="selected.indexOf(item.id) !== -1 ? 'selected' : ''" v-for="(item, key) in itemsSearch" :key="key" @click="click(item.id, item.fio ? item.fio : item.name)">{{item.fio ? item.fio : item.name}}</span>
      </div>
    </perfect-scrollbar>
    <span class="input-custom-select__error">! {{errorMsg}}</span>
  </div>
</template>

<script>
import search from '@/components/search/search.vue'

export default {
  name: 'selectForm',
  components: { search },
  props: ['select', 'items', 'placeholder', 'name', 'required', 'multiple', 'errorMsg', 'nameSelected', 'search', 'searchPlaceholder'],
  data () {
    return {
      open: false,
      selected: this.$props.select ? this.$props.select : [],
      nameSelect: this.$props.nameSelected ? this.$props.nameSelected.toLocaleString() : '',
      nameArr: this.$props.nameSelected ? this.$props.nameSelected : [],
      itemsSearch: ''
    }
  },
  methods: {
    openSelect () {
      this.open = !this.open
    },
    closeSelect () {
      this.open = false
    },
    getSearch (e) {
      if (this.items && e) {
        const arr = []
        this.items.forEach(el => {
          if (el.family.toUpperCase().indexOf(e.toUpperCase()) > -1) {
            arr.push(el)
          }
        })
        this.itemsSearch = arr
      } else {
        this.itemsSearch = null
      }
    },
    click (id, name) {
      if (this.$props.multiple) {
        if (Array.isArray(this.selected)) {
          if (this.selected.indexOf(id) !== -1) {
            this.selected.splice(this.selected.indexOf(id), 1)
            if (this.nameArr.length > 0) {
              this.nameArr.splice(this.nameArr.indexOf(name), 1)
            } else {
              this.nameArr = [name]
            }
            this.nameSelect = this.nameArr.toLocaleString()
          } else {
            this.selected.push(id)
            this.nameArr.push(name)
            this.nameSelect = this.nameArr.toLocaleString()
          }
        } else {
          this.selected = [id]
          this.nameArr = [name]
          this.nameSelect = this.nameArr.toLocaleString()
        }
      } else {
        this.selected = id
        this.nameSelect = name
        if (document.querySelector('.dop-city') !== null) {
          if (id === '0') {
            document.querySelector('.dop-city').classList.remove('hide')
          } else {
            document.querySelector('.dop-city').classList.add('hide')
          }
        }
        this.$emit('customInput', this.selected)
        this.closeSelect()
      }
    }
  }
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.select-form
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  position: relative
  border-bottom: 1px solid $darkGray
  margin: 0 0 20px 0
  &.error
    margin: 0 0 25px 0
    border-bottom: 1px solid $red
    .input-custom-select__error
      display: block
.select-form__select
  width: 1px
  height: 1px
  @include resetBtn
.input-custom-select__error
  position: absolute
  top: 106%
  left: 0
  display: none
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 130%
  color: $red
.select-form__result
  width: 100%
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  color: $black
  background: transparent
  border: none
  outline: none
  cursor: pointer
  &::placeholder
    font-weight: 400
    color: $darkGray
.select-form__arrow
  cursor: pointer
  margin: 0 15px 0 auto
  &.active
    transform: rotate(180deg)
.select-form__list
  position: absolute !important
  top: 100%
  left: 0
  width: 100%
  background: $white
  border: 1px solid #2C2A29
  border-radius: 0 0 10px 10px
  height: 240px
  z-index: 998
.select-form__list-label
  display: flex
  width: 100%
.select-form__list-name
  @include transition
  width: 100%
  display: block
  box-sizing: border-box
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 160%
  letter-spacing: 0.03em
  word-wrap: break-word
  width: 100%
  padding: 15px 20px
  color: $black
  cursor: pointer
  &:hover
    background: $darkGray
    color: $white
  &.selected
    background: $darkGray !important
    color: $white !important
.select-form__search
  box-sizing: border-box
  padding: 20px !important
  svg
    left: 20px
</style>
