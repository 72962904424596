<template>
  <router-link class="header__logo" :to="url" v-if="url">
  </router-link>
</template>

<script>
export default {
  name: 'headerLogo',
  props: ['url']
}
</script>

<style lang="sass">
@import "../../../mixin"
.header__logo
  @include flexRowNoWrap
  @include transition
  height: 60px
  background-position: center
  background-size: contain
  background-repeat: no-repeat
  width: 100%
  svg
    width: 100%
    height: 100%
    object-fit: cover
  &.bu
    background-image: url("@/assets/img/svg/бю-чб.svg")
    &:hover
      background-image: url("@/assets/img/svg/БЮ-цв.svg")
  &.na
    position: relative
    background-image: url("@/assets/img/svg/Нац.проекты-чб.svg")
    &:before
      position: absolute
      top: 91px
      left: -117px
      width: 332px
      height: 120px
      content: ''
      background-image: url("@/assets/img/jpgPng/logoObject2.jpg")
      background-size: cover
      opacity: 0
      pointer-events: none
      transition: 0.35s
    &:hover
      background-image: url("@/assets/img/svg/Нац.проекты-цв.svg")
      &:before
        opacity: 1
  &.ge
    background-image: url("@/assets/img/svg/герб-чб.svg")
    &:hover
      background-image: url("@/assets/img/svg/герб-цв.png")
@media (max-width: 1919px)
  .header__logo
    height: 60px
    &.na
      &:before
        top: 74px
@media (max-width: 1364px)
  .header__logo
    height: 40px
    &.na
      &:before
        display: none
  .header.min
    .header__logo
      height: 40px
</style>
