import { axiosGet } from '@/service/axios'
import router from '@/router'

export const online = {
  state () {
    return {
      items: '',
      pageNav: ''
    }
  },
  mutations: {
    stateOnlineItems (state, data) {
      state.items = data
    },
    stateOnlinePageNav (state, data) {
      state.pageNav = data
    }
  },
  actions: {
    getOnlineItemsAndPageNav ({ dispatch, commit }) {
      axiosGet(`api${router.currentRoute.value.fullPath}`, {}, (data) => {
        if (data.items.length !== 0) {
          commit('stateOnlineItems', data)
          commit('stateOnlinePageNav', data.pageNav)
        } else {
          commit('stateOnlineItems', '')
          commit('stateOnlinePageNav', '')
        }
      })
    }
  }
}
