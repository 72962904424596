<template>
  <div class="header__menu-btn" :class="headerMenuBtn ? 'active' : ''" @click="stateInverseHeaderMenuBtn">
    <span class="header__menu-btn-name">меню</span>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.84286 1.8C7.0493 1.8 6.26351 1.9563 5.53036 2.25998C4.7972 2.56367 4.13104 3.00878 3.56991 3.56991C3.00878 4.13104 2.56367 4.7972 2.25998 5.53036C1.9563 6.26351 1.8 7.0493 1.8 7.84286C1.8 8.63642 1.9563 9.4222 2.25998 10.1554C2.56367 10.8885 3.00878 11.5547 3.56991 12.1158C4.13104 12.6769 4.7972 13.122 5.53036 13.4257C6.26351 13.7294 7.0493 13.8857 7.84286 13.8857C9.44552 13.8857 10.9825 13.2491 12.1158 12.1158C13.2491 10.9825 13.8857 9.44552 13.8857 7.84286C13.8857 6.24019 13.2491 4.70317 12.1158 3.56991C10.9825 2.43666 9.44552 1.8 7.84286 1.8ZM4.84153 0.597002C5.79306 0.202861 6.81292 0 7.84286 0C9.92291 5.36442e-08 11.9178 0.826299 13.3886 2.29712C14.8594 3.76794 15.6857 5.7628 15.6857 7.84286C15.6857 9.62066 15.0821 11.3362 13.9884 12.7156L17.7364 16.4636C18.0879 16.8151 18.0879 17.3849 17.7364 17.7364C17.3849 18.0879 16.8151 18.0879 16.4636 17.7364L12.7156 13.9884C11.3362 15.0821 9.62066 15.6857 7.84286 15.6857C6.81292 15.6857 5.79306 15.4829 4.84153 15.0887C3.88999 14.6946 3.0254 14.1169 2.29712 13.3886C1.56884 12.6603 0.991143 11.7957 0.597002 10.8442C0.202862 9.89265 0 8.8728 0 7.84286C0 6.81292 0.202862 5.79306 0.597002 4.84153C0.991142 3.88999 1.56884 3.0254 2.29712 2.29712C3.0254 1.56884 3.88999 0.991142 4.84153 0.597002Z" fill="#2C2A29"/>
    </svg>
    <i class="header__menu-btn-line-top" />
    <i class="header__menu-btn-line-middle" />
    <i class="header__menu-btn-line-bottom" />
  </div>
</template>

<script>
export default {
  name: 'headerMenuBtn',
  computed: {
    headerMenuBtn () {
      return this.$store.state.header.headerMenuBtn
    }
  },
  methods: {
    stateInverseHeaderMenuBtn () {
      this.$store.commit('stateInverseHeaderMenuBtn')
    }
  }
}
</script>

<style lang="sass">
@import "../../../mixin"
@import "../../../variables"
.header__menu-btn
  @include flexColumn
  position: relative
  width: 37px
  height: 33px
  cursor: pointer
  overflow: hidden
  margin: 0 75px 0 0
  &:hover
    .header__menu-btn-line-top,
    .header__menu-btn-line-bottom,
    .header__menu-btn-line-middle
      background: $red
      @media (min-width: 1365px)
        width: 90%
  &.active
    .header__menu-btn-name
      top: -18px
    .header__menu-btn-line-top
      top: calc(50% - 2px)
      width: 100%
      transform: rotate(45deg)
    .header__menu-btn-line-bottom
      top: calc(50% - 2px)
      width: 100%
      transform: rotate(-45deg)

.header__menu-btn svg
  display: none

.header__menu-btn-name
  @include transition
  position: absolute
  top: 0
  left: 0
  font-family: 'Jost', sans-serif
  font-weight: 600
  font-size: 10px
  text-transform: uppercase
  line-height: 160%
  letter-spacing: 0.1em
  margin: 0 0 5px 0

.header__menu-btn-line-top,
.header__menu-btn-line-bottom,
.header__menu-btn-line-middle
  position: absolute
  left: 0
  height: 2px
  background: $black

.header__menu-btn-line-top
  @include transition
  top: calc(70% - 2px)
  width: 30px
  margin: 0

.header__menu-btn-line-bottom
  @include transition
  top: calc(100% - 2px)
  width: 18px
  margin: 0

.header__menu-btn-line-middle
  @include transition
  display: none

@media (max-width: 1919px)
  .header__menu-btn
    margin: 0 60px 0 0

@media (max-width: 1364px)
  .header__menu-btn
    margin: 0 0 0 auto
    order: 2
    &.active
      svg
        left: -18px
        @inclide transition
      .header__menu-btn-name
        top: -18px
      .header__menu-btn-line-top
        transform: rotate(-45deg)
        width: 100%
        top: 50%
        left: 0
      .header__menu-btn-line-bottom
        transform: rotate(45deg)
        width: 100%
        top: 50%
        left: 0
      .header__menu-btn-line-middle
        width: 0px

  .header__menu-btn svg
    display: flex
    position: absolute
    bottom: 0
    left: 0
    @include transition

  .header__menu-btn-line-top
    @include transition
    width: 20px
    top: 16px
    right: 0
    margin: 0
    left: auto
    border-radius: 4px

  .header__menu-btn-line-bottom
    @include transition
    width: 14px
    margin: 0
    right: 0
    left: auto
    border-radius: 4px

  .header__menu-btn-line-middle
    top: 23.5px
    border-radius: 4px
    width: 18px
    right: 0
    left: auto
    display: block
</style>
