<template>
  <div class="step">
    <svg class="step-big" width="94" height="60" viewBox="0 0 94 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="white" stroke="#2C2A29"/>
      <rect width="44" height="20" transform="translate(50 16)" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M50 24.75H89.25L82.25 17.75L84 16L94 26L84 36L82.25 34.25L89.25 27.25H50V24.75Z" fill="#2C2A29"/>
    </svg>
    <svg class="step-small" width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="white" stroke="#2C2A29"/>
      <path d="M11.25 28.5L9.75 28.5L9.75 30L9.75 35.5287L8.11066 33.8893L7.05 32.8287L5.98934 33.8893L4.93934 34.9393L3.87868 36L4.93934 37.0607L10.9393 43.0607L12 44.1213L13.0607 43.0607L19.0607 37.0607L20.1213 36L19.0607 34.9393L18.0107 33.8893L16.95 32.8287L15.8893 33.8893L14.25 35.5287L14.25 30L14.25 28.5L12.75 28.5L11.25 28.5Z" fill="#2C2A29" stroke="white" stroke-width="3"/>
    </svg>
    <div>
      <p class="step__text">{{text}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'step',
  props: ['text', 'renderContent']
}
</script>

<style lang="sass">
@import "../../mixin"
@import "../../variables"
.step
  @include flexRowNoWrap
  position: relative
  counter-increment: step
  margin: 0 0 20px 0
  svg
    flex: 0 0 auto
  &:before
    position: absolute
    top: 9px
    left: 6px
    content: counter(step, decimal-leading-zero)
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 700
    font-size: 20px
    line-height: 150%
    text-align: center
    letter-spacing: 0.03em
    color: $black
.step__text
  margin: 9px 0 0 20px
.step-small
  display: none
@media (max-width: 767px)
  .step-big
    display: none
  .step-small
    display: block
  .step
    display: block
    &:before
      top: 0
      left: 8px
  .step__text
    margin: 0 0 0 0
</style>
