import { axiosGet } from '@/service/axios'
import router from '@/router'

export const successHistoriesDetail = {
  state () {
    return {
      items: ''
    }
  },
  mutations: {
    stateSuccessHistoriesDetailItems (state, data) {
      state.items = data
    }
  },
  actions: {
    getSuccessHistoriesDetailItemsAndPageNav ({ dispatch, commit }) {
      if (router.currentRoute.value.params.id) {
        axiosGet(`api/keep-up/success-histories/${router.currentRoute.value.params.id}`, {}, (data) => {
          if (data.length !== 0) {
            commit('stateSuccessHistoriesDetailItems', data)
          } else {
            commit('stateSuccessHistoriesDetailItems', '')
          }
        })
      }
    }
  }
}
