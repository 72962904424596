<template>
  <a class="header__tel" :href="url"><img class="header__tel-img" :src="require('@/assets/img/svg/flag.svg')">{{ tel }}</a>
</template>

<script>
export default {
  name: 'headerTel',
  props: ['url', 'tel']
}
</script>

<style lang="sass">
@import "../../../variables"
@import "../../../mixin"
.header__tel
  @include transition
  @include resetBtn
  position: relative
  font-family: 'Jost', sans-serif
  font-weight: 700
  font-size: 27px
  line-height: 39px
  color: $black
  margin: 0 111px 0 0
  &:hover
    .header__tel-img
      opacity: 1
.header__tel-img
  @include transition
  position: absolute
  top: 50%
  left: -10px
  object-fit: contain
  z-index: -1
  opacity: 0
@media (max-width: 1919px)
  .header__tel
    margin: 0 0 0 0
@media (max-width: 1364px)
  .header__tel
    margin: 0 auto
    order: 1
    font-size: 20px
  .header__tel-img
    width: 212px
    top: 40%
@media (max-width: 767px)
  .header__tel
    display: none
</style>
