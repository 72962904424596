import { axiosPost } from '@/service/axios'

export const favorites = {
  state () {
    return {
      content: '',
      likes: []
    }
  },
  mutations: {
    stateFavoriteContent (state, data) {
      state.content = data
    },
    stateFavoriteLikes (state, data) {
      state.likes = data
    }
  },
  actions: {
    getFavoritesContent ({ dispatch, commit }) {
      let getAddSupport
      if (localStorage.getItem('supportCard') && localStorage.getItem('supportCard').length !== 0) {
        getAddSupport = JSON.parse(localStorage.getItem('supportCard'))
      }
      commit('stateFavoriteLikes', getAddSupport)
      axiosPost('api/favorites', { favorites: getAddSupport }, (data) => {
        if (data.length !== 0) {
          commit('stateFavoriteContent', data)
        } else {
          commit('stateFavoriteContent', '')
        }
      })
    }
  }
}
