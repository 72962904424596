import { axiosGet } from '@/service/axios'
import router from '@/router'

export const search = {
  state () {
    return {
      search: '',
      searchHeader: ''
    }
  },
  mutations: {
    stateSearch (state, data) {
      state.search = data
    },
    stateHeaderSearch (state, data) {
      state.searchHeader = data
    }
  },
  actions: {
    getSearch ({ dispatch, commit }) {
      axiosGet(`api${router.currentRoute.value.fullPath}`, {}, (data) => {
        commit('stateSearch', data)
      })
    },
    getHeaderSearch ({ dispatch, commit }, data) {
      axiosGet(`api/search?search=${data}`, {}, (data) => {
        commit('stateHeaderSearch', data)
      })
    }
  }
}
