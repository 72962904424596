<template>
  <div class="accordion" :class="open ? 'active' : ''">
    <div class="accordion__header" @click="click">
      <p class="accordion__header-title">{{title}}</p>
      <div class="accordion__plus">
        <i class="accordion__header-line-1" />
        <i class="accordion__header-line-2" />
      </div>
    </div>
    <div class="accordion__body">
      <div class="accordion__body-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'accordion',
  props: ['title'],
  data () {
    return {
      open: false
    }
  },
  methods: {
    click () {
      this.open = !this.open
    }
  }
}
</script>

<style lang="sass">
@import "../../variables"
@import "../../mixin"
.accordion
  width: 100%
  margin: 0 0 20px 0
  &.active
    & > .accordion__body > .accordion__body-content
      height: auto
      padding: 20px 0
    & > .accordion__header .accordion__header-line-2
      transform: rotate(0deg)
    & > .accordion__header
      &:hover
        .accordion__header-line-1
          transform: rotate(-45deg)
          left: -14px
        .accordion__header-line-2
          transform: rotate(45deg)
          left: 14px
  .accordion
    padding: 0 30px 0 30px
    box-sizing: border-box
.accordion__plus
  position: relative
  width: 40px
  height: 40px
  flex: 0 0 auto
.accordion__header-line-1,
.accordion__header-line-2
  position: absolute
  top: 19px
  left: 0
  width: 40px
  height: 1px
  background: $black
.accordion__header-line-2
  transform: rotate(90deg)
.accordion__header
  @include flexRowNoWrap
  @include flexAlignItemsCenter
  @include flexJustifyContentSpace
  @include borderBox
  padding: 20px 40px 20px 0
  border-bottom: 1px solid $black
  cursor: pointer
  &:hover
    .accordion__header-line-1
      transform: rotate(45deg)
      left: -14px
    .accordion__header-line-2
      transform: rotate(135deg)
      left: 14px
.accordion__header-title
  @include transition
  font-family: 'Jost', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 25px
  line-height: 36px
  letter-spacing: 0.03em
  color: $black
  margin: 0
.accordion__body
  @include transition
.accordion__body-content
  @include borderBox
  height: 0
  overflow: hidden
.accordion__header-line-1, .accordion__header-line-2
  @include transition
@media (max-width: 767px)
  .accordion__header
    padding: 20px 10px 20px 0
</style>
